import React from 'react'
import FighterSettings from '../../modules/fighters/components/FighterSettings'
import Wrapper from '../layout'

const FighterProfilePage = (props) => {
  return (
    <Wrapper {...props} title="Settings">
      <div className="container">
        <FighterSettings />
      </div>
    </Wrapper>
  )
}

export default FighterProfilePage
