import { EHostnames } from 'components/constants'
import { Page } from 'components/Loader'
import { useRootStore } from 'hooks'
// import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import useGetNodeEnvironment, {
  ENodeEnvironment
} from 'modules/common/hooks/useGetNodeEnvironment'
import { SiteFavicon } from 'pages/layout/SiteLogo'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import Routes from './routes'

const TRACKING_ID_SKYCRU = 'G-L1Q4XE9WQ5'
const TRACKING_ID_FAIRFIGHTS = 'G-DPQMKB1LFT'

export const App = () => {
//  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType()
  const { domainData } = useRootStore()
  const nodeEnv = useGetNodeEnvironment()

// HubSpot - conditions to display tracking code
  useEffect(() => {
    if (!domainData?.key || nodeEnv !== ENodeEnvironment.PRODUCTION) {
      return; // Exit if not in production or domain key is not defined
    }

    const script = document.createElement('script');

    switch (domainData.key) {
      case EHostnames.SKYCRU:
        script.src = '//js.hs-scripts.com/42005218.js';
        break;
      case EHostnames.FAIRFIGHTS:
        script.src = '//js.hs-scripts.com/47967727.js';
        break;
      default:
        return; // Exit if no matching domain key
    }

    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [nodeEnv, domainData?.key]);

  // Google Analytics - conditions to display tracking code
  useEffect(() => {
    if (!domainData?.key || nodeEnv !== ENodeEnvironment.PRODUCTION)  {
      return; // Exit if not in production or domain key is not defined
    }

    switch (domainData.key) {
      case EHostnames.SKYCRU:
        ReactGA.initialize(TRACKING_ID_SKYCRU);
        break;
      case EHostnames.FAIRFIGHTS:
        ReactGA.initialize(TRACKING_ID_FAIRFIGHTS);
        break;
      default:
        break; // No action for other keys
    }
  }, [nodeEnv, domainData?.key]);

  return (
    <Page>
      <SiteFavicon />
      <Routes />
    </Page>
  )
}
