import BulkEmailModal from 'components/Event/Modals/BulkEmailModal'
import { BulkLogPaymentModal } from 'components/Event/Modals/BulkPaymentLogForm'
import { PaymentButtons } from 'modules/payment/components/buttons/PaymentButtons'
import RegistrationPaymentsModal from 'modules/registration/payments/modals/RegistrationPaymentsModal'
import React, { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import {
  Avatar,
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Icon,
  Table,
  Tag,
  Text
} from 'tabler-react'
import { Types } from 'types/graphql'
import useReactRouter from 'use-react-router'
import { formatMoney } from '../../../utils/numberFormat'
import PaymentsCSV from '../../payment/components/buttons/PaymentsCSV'

type EventFinanceProps = {
  campData: Types.Camp
  registeredStudents: Types.RegisteredStudents[]
}
const EventFinance = ({ campData, registeredStudents }: EventFinanceProps) => {
  const [isBulkLogPaymentModalOpen, setIsBulkLogPaymentModalOpen] =
    useState<boolean>(false)
  const [isBulkEmailModalOpen, setIsBulkEmailModalOpen] =
    useState<boolean>(false)
  const [selectedParticipant, setSelectedParticipant] = useState<
    Types.RegisteredStudents[]
  >([])

  const participants = useMemo(() => {
    if (!registeredStudents) return []

    const validStatuses = ['Confirmed', 'Accepted']
    return registeredStudents.filter((student) =>
      validStatuses.includes(student.status)
    )
  }, [registeredStudents])

  const participantsCSV = useMemo(() => {
    if (!registeredStudents) return []

    return registeredStudents
      .filter((student) => student.status === 'Confirmed')
      .map((student) => {
        const remainingBalance = student.paymentDetail.outstanding / 100
        const compTotal = student.paymentDetail.totalComp / 100
        const totalFees = student.paymentDetail.total / 100
        const totalPaid = student.paymentDetail.totalPaid / 100
        let campGroupName = ''

        if (student.campGroups && student.campGroups.length > 0)
          campGroupName = student.campGroups[0].name

        const team = campData.registration_groups ? { team: campGroupName } : {}
        const discipline =
          campData.event_type_id === '2'
            ? { discipline: student.discipline }
            : {}

        return {
          userId: student.student_id,
          name: student.first_name + ' ' + student.last_name,
          registrationId: student.user_registrations_id,
          code: student.registration_code,
          ...team,
          ...discipline,
          total: formatMoney(totalFees, campData.registration_currency),
          paid: formatMoney(totalPaid, campData.registration_currency),
          balance: formatMoney(
            remainingBalance > 0 ? remainingBalance : 0,
            campData.registration_currency
          )
        }
      })
  }, [registeredStudents])

  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleToggleSelectAll = (selectedAll) => {
    if (selectedAll) {
      setSelectedParticipant(
        registeredStudents.filter((student) => student.status === 'Confirmed')
      )
    } else {
      setSelectedParticipant([])
    }
  }

  const handleToggleSelected = (id: number) => {
    const pool = registeredStudents.filter(
      (student) => student.status === 'Confirmed'
    )
    if (
      selectedParticipant?.find((student) => {
        return student.user_registrations_id === id
      })
    ) {
      setSelectedParticipant(
        (prev) => prev?.filter((p) => p.user_registrations_id !== id) ?? []
      )
    } else {
      setSelectedParticipant((prev) => [
        ...(prev ?? []),
        pool.find((student) => student.user_registrations_id === id)
      ])
    }
  }

  return (
    <>
      <Card.Body>
        <Grid.Row className="p-3">
          <Grid.Col width={1} className="pt-2">
            <Form.Label>
              <input
                id={`selectAll`}
                name={'select_all'}
                type="checkbox"
                checked={selectAll}
                onClick={() => {
                  setSelectAll(!selectAll)
                  handleToggleSelectAll(!selectAll)
                }}
                onChange={() => {}}
                className="float-left pb-2"
              />
            </Form.Label>
          </Grid.Col>
          <Grid.Col width={11} className="text-right">
            <Button
              outline={selectedParticipant.length !== 0}
              icon="credit-card"
              color={selectedParticipant.length !== 0 ? 'secondary' : 'white'}
              size={'sm'}
              onClick={() => {
                setIsBulkLogPaymentModalOpen(true)
              }}
              className="mr-2"
              disabled={selectedParticipant.length === 0}
            >
              PAYMENTS
            </Button>
            <Button
              outline={selectedParticipant.length !== 0}
              icon="mail"
              color={selectedParticipant.length !== 0 ? 'secondary' : 'white'}
              size={'sm'}
              className="mr-2"
              onClick={() => {
                setIsBulkEmailModalOpen(true)
              }}
              disabled={selectedParticipant.length === 0}
            >
              EMAIL
            </Button>
            <Dropdown
              className="cursor-pointer"
              toggle={false}
              triggerContent={
                <Button color={'secondary'} size={'sm'} outline>
                  <Icon name="download" className={'mr-1'} />
                  CSV
                </Button>
              }
              position="bottom-end"
              align="right"
              items={[
                <>
                  <Dropdown.Item>
                    <CSVLink
                      data={participantsCSV}
                      filename="finance_report.csv"
                      className="ml-0 text-decoration-none text-default"
                    >
                      <Icon name="download" className="mr-1" />
                      Finance Report
                    </CSVLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <PaymentsCSV
                      campId={campData.id}
                      filename={'event_payments'}
                      name={'Payments Report'}
                    />
                  </Dropdown.Item>
                </>
              ]}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <Table responsive className="table-vcenter text-nowrap">
              <Table.Body>
                {participants.map((student) => (
                  <Table.Row key={`registration-${student.id}`}>
                    <EventFinanceItem
                      student={student}
                      camp={campData}
                      checked={
                        !!selectedParticipant.find(
                          (p) =>
                            p.user_registrations_id ===
                            student.user_registrations_id
                        )
                      }
                      handleToggleSelected={handleToggleSelected}
                    />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
      {campData && (
        <>
          <BulkLogPaymentModal
            isOpen={isBulkLogPaymentModalOpen}
            toggle={() => setIsBulkLogPaymentModalOpen(false)}
            students={selectedParticipant}
            currency={campData.registration_currency}
            teamId={Number(campData.team_id)}
          />
          <BulkEmailModal
            isOpen={isBulkEmailModalOpen}
            toggle={() => setIsBulkEmailModalOpen(false)}
            students={selectedParticipant}
            teamId={Number(campData.team_id)}
            campId={Number(campData.id)}
          />
        </>
      )}
    </>
  )
}

export default EventFinance

const EventFinanceItem = ({ student, camp, checked, handleToggleSelected }) => {
  {
    const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false)
    const { history } = useReactRouter()
    const { total, outstanding, totalPaid, totalFees, totalComp } =
      student.paymentDetail

    const paymentStatus = useMemo(() => {
      if (totalComp === total) {
        return 'Comp'
      }

      if (outstanding === 0) {
        return 'Paid'
      }
      return ''
    }, [totalComp, outstanding, totalFees])

    let campGroupName = ''
    if (student.campGroups && student.campGroups.length > 0)
      campGroupName = student.campGroups[0].name
    return (
      <>
        <Table.Col>
          <input
            id={`participant-${student.student_id}`}
            name={'selected'}
            type="checkbox"
            checked={checked}
            onClick={() => handleToggleSelected(student.user_registrations_id)}
            onChange={() => {}}
          />
        </Table.Col>
        <Table.Col>
          <Avatar
            size="md"
            className="cursor-pointer"
            status={student.status_color ?? 'danger'}
            imageURL={
              student?.profile_avatar
                ? `/api/s3/uploads/${student.profile_avatar}`
                : '//www.gravatar.com/avatar?d=mp'
            }
            onClick={() =>
              history.push(
                `/events/registrations/${student.user_registrations_id}/${student.student_id}`
              )
            }
          />
        </Table.Col>
        <Table.Col>
          <strong>
            <Text
              className="cursor-pointer d-block"
              onClick={() =>
                history.push(
                  `/events/registrations/${student.user_registrations_id}/${student.student_id}`
                )
              }
            >
              {student.first_name + ' ' + student.last_name}
            </Text>
          </strong>
        </Table.Col>
        <Table.Col>{campGroupName}</Table.Col>
        <Table.Col>
          {student?.camp_fees > 0 && (
            <span className={'d-block'}>
              <Tag color="white" className="text-muted">
                Registration
              </Tag>
              <Tag className="ml-2 float-right">
                {formatMoney(student.camp_fees, student.registration_currency)}
              </Tag>
            </span>
          )}
          {student.default_jump_price && (
            <span className={'d-block'}>
              <Tag color="white" className="text-muted">
                Jump Tickets x {student?.jump_quantity ?? 1}
              </Tag>
              <Tag className="ml-2 float-right">
                {formatMoney(
                  Number(student.default_jump_price) *
                    (student?.jump_quantity ?? 1),
                  student.registration_currency
                )}
              </Tag>
            </span>
          )}
          {student.sanction_fee > 0 && (
            <span className={'d-block'}>
              <Tag color="white" className="text-muted">
                Sanction Fee:{' '}
              </Tag>
              <Tag className="ml-2 float-right">
                {formatMoney(
                  student.sanction_fee,
                  student.sanction_fee_currency
                )}
              </Tag>
            </span>
          )}
          {student.user_registration_options.length > 0 &&
            student.user_registration_options.map((option) => {
              return (
                <span className={'d-block'} key={option.id}>
                  <Tag color="white" className="text-muted">
                    {option.name}{' x '}
                    {option.quantity ?? 1}
                  </Tag>
                  <Tag className="ml-2 float-right">{option.option_value &&
                    formatMoney(
                      option.option_value,
                      student.registration_currency
                    )}</Tag>
                </span>
              )
            })}
        </Table.Col>
        <Table.Col>
          <PaymentButtons
            totalComp={totalComp}
            totalPaid={totalPaid}
            outstanding={outstanding}
            paymentStatus={paymentStatus}
            onClick={() => setOpenPaymentModal(true)}
            status={student.status}
            currency={student.registration_currency}
          />

          {openPaymentModal && (
            <RegistrationPaymentsModal
              outstanding={outstanding / 100}
              totalPaid={totalPaid / 100}
              userRegistrationDetails={student}
              open={openPaymentModal}
              onClose={() => setOpenPaymentModal(false)}
              currency={student.registration_currency}
            />
          )}
        </Table.Col>
        <Table.Col>
          {/*
          <Icon name="more-vertical" />
          */}
        </Table.Col>
      </>
    )
  }
}
