import { Grid, StampCard, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import formatNumber, { formatMoney } from 'utils/numberFormat'

const EventRegistrationTotalPayments = ({ registrations }) => {
  if (registrations.length === 0) {
    return null
  }
  const totals = {
    paidPaymentsCount: 0,
    paidPaymentsAmount: 0,
    compPaymentsCount: 0,
    compPaymentsAmount: 0,
    registration_currency: registrations[0].registration_currency
  }
  registrations.forEach((registration) => {
    totals.paidPaymentsAmount += registration.paymentDetail.totalPaid / 100
    totals.paidPaymentsCount += registration.paymentDetail.paidCount
    totals.compPaymentsAmount += registration.paymentDetail.totalComp / 100
    totals.compPaymentsCount += registration.paymentDetail.compCount
  })

  return <EventRegistrationTotalPaymentsInner payments={totals} />
}

const EventRegistrationTotalPaymentsInner = ({ payments }) => {
  const { history } = useReactRouter()
  return (
    <>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span>
          <StampCard
            className="mb-0"
            color="success"
            icon="dollar-sign"
            // Total number of payments with Paid status for this camp
            header={
              <>
                <Text>
                  <span
                    className="cursor-pointer"
                    onClick={() => history.push(`/payments`)}
                  >
                    {formatNumber(payments.paidPaymentsCount)}{' '}
                    <small>Payments</small>
                  </span>
                </Text>
              </>
            }
            // Total payment amounts with Paid status, Total Registration Revenue
            footer={
              <Text>
                {formatMoney(
                  payments.paidPaymentsAmount ?? 0,
                  payments.registration_currency
                )}
              </Text>
            }
          />
        </span>
      </Grid.Col>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span>
          <StampCard
            className="mb-0"
            color="secondary"
            icon="dollar-sign"
            header={
              <Text>
                {formatNumber(payments.compPaymentsCount)}{' '}
                <small>Comp Payments</small>
              </Text>
            }
            footer={
              <Text>{`${formatMoney(
                payments.compPaymentsAmount,
                payments.registration_currency
              )}`}</Text>
            }
          />
        </span>
      </Grid.Col>
    </>
  )
}

export default EventRegistrationTotalPayments
