import { useMutation, useQuery } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { DEFAULT_PRODUCT_LIMIT } from 'components/constants'
import { Loading } from 'components/Loading'
import AccountBalanceSuccessModal from 'components/Stripe/account-balance/AccountBalanceSuccessModal'
import StripeAccountBalanceModal from 'components/Stripe/account-balance/StripeAccountBalanceModal'
import AccountBalance from 'components/User/AccountBalance'
import { UserStoreContext } from 'contexts/userStoreContext'
import { ADD_STUDENT_ACCOUNT_BALANCE } from 'graphql/ADD_STUDENT_ACCOUNT_BALANCE'
import { GET_ACCOUNT_BALANCE } from 'graphql/GET_ACCOUNT_BALANCE'
import { GET_ACCOUNT_BALANCE_PAYMENT_HISTORY } from 'graphql/GET_ACCOUNT_BALANCE_PAYMENT_HISTORY'
import { GET_PAYMENT_FEE_BY_TEAM_ID } from 'graphql/GET_PAYMENT_FEE_BY_TEAM_ID'
import { GET_PRODUCTS_BY_FILTER_QUERY } from 'graphql/GET_PRODUCTS_BY_FILTER_QUERY'
import { GET_STRIPE_PUBLIC_KEY } from 'graphql/GET_STRIPE_PUBLIC_KEY'
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from 'graphql/types/GET_ACCOUNT_BALANCE'
import { GET_ACCOUNT_BALANCE_PAYMENT_HISTORY as GET_ACCOUNT_BALANCE_PAYMENT_HISTORY_TYPE } from 'graphql/types/GET_ACCOUNT_BALANCE_PAYMENT_HISTORY'
import { GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE } from 'graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID'
import { GET_PRODUCTS_BY_FILTER_QUERY as GET_PRODUCTS_BY_FILTER_QUERY_TYPE } from 'graphql/types/GET_PRODUCTS_BY_FILTER_QUERY'
import { useRootStore } from 'hooks'
import { EOrderOptions } from 'hooks/useAutocomplete'
import { useCoachTeam } from 'modules/common/hooks/useGetCurrentUserType'
import React, { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Card, Icon, Table, Tag } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { formatMoney } from 'utils/numberFormat'

const AccountBalanceCard = () => {
  const { currentCoachTeam } = useRootStore()
  const { loading, team, error } = useCoachTeam({
    teamId: currentCoachTeam.id
  })
  const { user } = useContext(UserStoreContext)

  const { data: accountBalance, refetch: refetchAccountBalance } =
    useQuery<GET_ACCOUNT_BALANCE_TYPE>(GET_ACCOUNT_BALANCE, {
      variables: {
        studentId: Number(user.id),
        teamId: Number(currentCoachTeam.id)
      }
    })

  const {
    data: accountBalancePaymentHistory,
    loading: accountBalancePaymentHistoryLoading,
    refetch: refetchAccountBalanceHistory
  } = useQuery<GET_ACCOUNT_BALANCE_PAYMENT_HISTORY_TYPE>(
    GET_ACCOUNT_BALANCE_PAYMENT_HISTORY,
    {
      variables: {
        input: {
          studentId: Number(user.id),
          teamId: Number(currentCoachTeam.id)
        },
        filter: {
          limit: 5
        }
      }
    }
  )

  const { data: productsData, loading: loadingProducts } =
    useQuery<GET_PRODUCTS_BY_FILTER_QUERY_TYPE>(GET_PRODUCTS_BY_FILTER_QUERY, {
      variables: {
        order: EOrderOptions.alphabet,
        filter: {
          team_id: currentCoachTeam.id,
          limit: DEFAULT_PRODUCT_LIMIT,
          offset: 0,
          status: 'account'
        }
      }
    })

  const products = useMemo(() => {
    if (!productsData) {
      return []
    }

    return productsData.products.map(
      ({ id, title, amount, quantity, currency }) => {
        return {
          value: id,
          label: title,
          id,
          title,
          amount,
          quantity,
          currency
        }
      }
    )
  }, [productsData])

  const { data: paymentFee } = useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(
    GET_PAYMENT_FEE_BY_TEAM_ID,
    {
      variables: {
        teamId: currentCoachTeam.id
      }
    }
  )

  if (
    loading ||
    loadingProducts ||
    accountBalancePaymentHistoryLoading ||
    !team
  ) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  const refetch = () => {
    refetchAccountBalance()
    refetchAccountBalanceHistory()
  }

  const paymentHistory =
    accountBalancePaymentHistory?.getAccountBalancePaymentHistory ?? []

  const props = {
    paymentFee,
    products,
    team,
    accountBalance,
    paymentHistory,
    user,
    refetch
  }

  return <AccountBalanceCardInner {...props} />
}

const AccountBalanceCardInner = ({
  team,
  paymentFee,
  products,
  accountBalance,
  paymentHistory,
  user,
  refetch
}) => {
  const { history } = useReactRouter()
  const { currentUser, currentCoachTeam } = useRootStore()

  const [amount, setAmount] = useState(0)
  const [email, setEmail] = useState('')

  const [isAccountBalanceModalOpen, setIsAccountBalanceModalOpen] =
    useState(false)
  const [isAccountBalanceStatusModalOpen, setIsAccountBalanceStatusModalOpen] =
    useState(false)

  const toggleAccountBalanceModal = () =>
    setIsAccountBalanceModalOpen(!isAccountBalanceModalOpen)
  const toggleAccountBalanceStatusModal = () =>
    setIsAccountBalanceStatusModalOpen(!isAccountBalanceStatusModalOpen)

  const { data: stripeData } = useQuery(GET_STRIPE_PUBLIC_KEY)
  const [addStudentAccountBalance] = useMutation(ADD_STUDENT_ACCOUNT_BALANCE)

  const stripeKey = useMemo(
    () => stripeData?.getStripePublicKey,

    [stripeData?.getStripePublicKey]
  )

  const onCompleted = () => {
    refetch()
  }

  const accountBalanceLink = `/user-details/${user.id}/student-teams/${currentCoachTeam.id}`
  return (
    <Card statusColor="gray-dark">
      <Card.Header>
        <Card.Title>
          <Link to={accountBalanceLink}>
            <Icon name="credit-card" className="mr-2 ml-0 text-secondary" />
            Account Balance
          </Link>
        </Card.Title>
        <Card.Options>
          <Button.List>
            <Button
              className="bg-transparent pr-0"
              size="lg"
              onClick={(e) => {
                e.preventDefault()
                setIsAccountBalanceModalOpen(true)
              }}
            >
              <AccountBalance
                accountBalance={
                  accountBalance?.getAccountBalance?.total_time ?? 0
                }
                slug={currentCoachTeam.account_type_slug}
              />
            </Button>

            {stripeKey && (
              <Elements
                stripe={loadStripe(
                  stripeKey,
                  currentCoachTeam?.stripe_account_id
                    ? {
                        apiVersion: '2023-08-16',
                        stripeAccount: currentCoachTeam.stripe_account_id
                      }
                    : {}
                )}
              >
                <StripeAccountBalanceModal
                  stripeAccountId={currentCoachTeam.stripe_account_id}
                  accountTypeSlug={currentCoachTeam.account_type_slug}
                  paymentFee={
                    paymentFee?.getPaymentFeeByTeamId.payment_fee_customer
                      ? paymentFee?.getPaymentFeeByTeamId.payment_fee_customer /
                        100
                      : 0
                  }
                  products={products}
                  isModalOpen={isAccountBalanceModalOpen}
                  studentAccountBalance={
                    accountBalance?.getAccountBalance?.total_time ?? 0
                  }
                  user={user}
                  team={team}
                  onCompleted={onCompleted}
                  toggleModal={toggleAccountBalanceModal}
                />
              </Elements>
            )}

            <AccountBalanceSuccessModal
              amount={amount.toFixed(2)}
              email={email}
              isModalOpen={isAccountBalanceStatusModalOpen}
              toggleModal={toggleAccountBalanceStatusModal}
            />
          </Button.List>
        </Card.Options>
      </Card.Header>
      <Card.Body className="p-1">
        <Table className="card-table">
          <Table.Body>
            {paymentHistory.length > 0 ? (
              paymentHistory.map((h) => (
                <Table.Row key={h.id}>
                  <Table.Col>
                    {new Intl.DateTimeFormat('en-US').format(
                      new Date(h.created_on)
                    )}
                    {h.manual_payment && (
                      <span className="ml-2">{h.manual_payment}</span>
                    )}
                    {h.processed_by === 'Manual Payment' ? (
                      ''
                    ) : (
                      <i className="fe fe-credit-card text-success ml-2" />
                    )}
                  </Table.Col>
                  <Table.Col className="text-right">
                    {formatMoney(h.amount)}
                    <Tag
                      color={h.status === 'Paid' ? 'success' : 'secondary'}
                      className="ml-2"
                    >
                      {h.status}
                    </Tag>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col colSpan="8">
                  <Alert type="info text-center">
                    <strong>No Payments</strong>
                  </Alert>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default AccountBalanceCard
