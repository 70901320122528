import { useQuery } from '@apollo/client'
import { Loading } from 'components/Loading'
import { STATUS_ICON_COLOR_MAPPING } from 'modules/user/registrations/constants/registrationStatusMapping'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Card, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { GET_STUDENT_CAMPS } from '../../graphql/GET_STUDENT_CAMPS'
import { useRootStore } from '../../hooks'
import useGetCurrentUserType, {
  useGetUserIsAdmin,
  useGetUserIsCoachSuperAdmin
} from '../../modules/common/hooks/useGetCurrentUserType'
import { Types } from '../../types/graphql'

interface IStudentRecentCamps {
  studentId: number
  camps: any
  currentCampId: number
}

const StudentRecentCamps = ({
  studentId,
  currentCampId
}: {
  studentId: number
  currentCampId: number
}) => {
  const { data, error, loading } = useQuery(GET_STUDENT_CAMPS, {
    variables: { getStudentCampsInput: { studentId: Number(studentId) } }
  })

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  const camps =
    [...data.getStudentCamps]
      .slice(0, 10)
      .filter(
        (c: Types.StudentCamps) => Number(c.camp_id) !== Number(currentCampId)
      ) || []

  return (
    <StudentRecentCampsInner
      studentId={studentId}
      camps={camps}
      currentCampId={currentCampId}
    />
  )
}

const StudentRecentCampsInner = ({
  studentId,
  camps,
  currentCampId
}: IStudentRecentCamps) => {
  const { history } = useReactRouter()
  const isAdmin = useGetUserIsAdmin()
  const isCoachSuperAdmin = useGetUserIsCoachSuperAdmin()
  const { currentCoachTeam } = useRootStore()
  const { isStudent } = useGetCurrentUserType()

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <i className="fe fe-calendar text-primary mr-2" />
          Other Events
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {camps.length <= 0 && (
          <Alert type="info text-center">
            <strong>No Events</strong>
          </Alert>
        )}
        {camps.length > 0 &&
          camps.map((camp) => {
            const enableLinks: boolean = useMemo(() => {
              if (isAdmin || isCoachSuperAdmin) return true
              return camp?.coHosts
                ? camp.coHosts.some((t) => t.id === currentCoachTeam.id)
                : false
            }, [isAdmin, isCoachSuperAdmin, camp])
            return (
              <div key={camp.camp_id}>
                <span className="ml-3 text-muted float-right">
                  {!isStudent && camp.rating > 0 && (
                    <Button
                      icon="star"
                      color="white"
                      size="sm"
                      className="text-warning"
                      disabled={!enableLinks}
                      onClick={(e) => {
                        e.preventDefault()
                        history.push(
                          `/events/registrations/${camp.user_registrations_id}/${studentId}`
                        )
                      }}
                    >
                      {camp.rating}
                    </Button>
                  )}
                  {camp.user_registrations_id && (
                    <Button
                      icon={
                        STATUS_ICON_COLOR_MAPPING.get(camp?.status)?.icon ??
                        'x-circle'
                      }
                      color="white"
                      size="sm"
                      className={
                        'ml-0 ' +
                          STATUS_ICON_COLOR_MAPPING.get(camp?.status)
                            ?.className ?? 'text-muted'
                      }
                      onClick={(e) => {
                        e.preventDefault()
                        history.push(
                          `/events/registrations/${camp.user_registrations_id}/${studentId}`
                        )
                      }}
                      disabled={!enableLinks}
                    />
                  )}
                </span>
                <p>
                  <span className="">
                    {enableLinks && (
                      <Link to={'/user/calendar/' + camp.camp_id}>
                        {camp.camp_name}
                      </Link>
                    )}
                    {!enableLinks && camp.camp_name}
                  </span>
                  <Text.Small className="d-block text-muted">
                    <strong>{moment(camp.start).format('MMM D, YYYY')}</strong>{' '}
                    @ {camp.location.name}
                  </Text.Small>
                  <Text.Small className="d-block text-muted">
                    {camp.team_name}
                  </Text.Small>
                </p>
              </div>
            )
          })}
      </Card.Body>
    </Card>
  )
}

export default StudentRecentCamps
