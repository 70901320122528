import { useQuery } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react'
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate'
import StudentsOnDashboard from 'modules/student/components/dashboard/StudentsOnDashboard'
import React from 'react'
import { Button, Card, Grid, Icon } from 'tabler-react'
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID } from '../../graphql/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID'
import { GET_STRIPE_PUBLIC_KEY } from '../../graphql/GET_STRIPE_PUBLIC_KEY'
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID as GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID_TYPE } from '../../graphql/types/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID'
import { useRootStore } from '../../hooks'
import useIsFeatureAccessible from '../../modules/common/hooks/useIsFeatureAccessible'
import GroupFormModal from '../Calendar/Modal/GroupFormModal'
import DashboardGroups from '../Coaches/DashboardGroups'
import TeamPublicDetails from '../Team/TeamPublicDetails'
import { EUserTypes } from '../User/userTypes'

const CoachDashboard = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { currentUser, currentCoachTeam } = useRootStore()

  const { isFeatureAccessible: hasDashboard } = useIsFeatureAccessible({
    currentCoachTeam,
    currentUserType: currentUser?.type as EUserTypes,
    feature: 'feature_dashboard'
  })

  const {
    data: stripeAccountData,
    loading: loadingStripe,
    error: errorStripe
  } = useQuery<GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID_TYPE>(
    GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID,
    {
      variables: {
        teamIdValue: currentCoachTeam?.id
      }
    }
  )

  const { data: stripeData, loading: stripePubLoading } = useQuery(
    GET_STRIPE_PUBLIC_KEY
  )

  const stripeKey = stripeData?.getStripePublicKey
  const stripeAccountId = stripeAccountData?.getStripeAccountIdByTeamId
    ? stripeAccountData?.getStripeAccountIdByTeamId.stripe_account_id
    : ''

  return (
    <>
      {!hasDashboard && currentCoachTeam ? (
        <TeamPublicDetails />
      ) : (
        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="home" className="mr-2 ml-0 text-primary" />
              Dashboard
            </Card.Title>
            <Card.Options>
              <Button.List align="right">
                <FeatureAccessGate feature="feature_dashboard_groups">
                  <Button
                    icon="plus"
                    color="primary"
                    onClick={() => setIsModalOpen(true)}
                  >
                    GROUP
                  </Button>
                </FeatureAccessGate>
              </Button.List>
            </Card.Options>
          </Card.Header>
        </Card>
      )}
      <Grid.Row>
        <Grid.Col width={12}>
          {stripeKey && (
            <Elements
              stripe={loadStripe(stripeKey, {
                apiVersion: '2023-08-16',
                stripeAccount: stripeAccountId
              })}
            >
              <StudentsOnDashboard missingInfo={true} />
            </Elements>
          )}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <FeatureAccessGate feature="feature_dashboard_groups">
          <Grid.Col width={12}>
            <DashboardGroups />
          </Grid.Col>
        </FeatureAccessGate>
        <GroupFormModal
          isOpen={isModalOpen}
          toggle={setIsModalOpen}
          teamId={currentCoachTeam?.id.toString()}
        />
      </Grid.Row>
      {/*
      <MyEventsCard />
      */}
      <FeatureAccessGate feature="feature_dashboard">
        <Grid.Col className="pl-0 pr-0">
          {stripeKey && (
            <Elements
              stripe={loadStripe(stripeKey, {
                apiVersion: '2023-08-16',
                stripeAccount: stripeAccountId
              })}
            >
              <StudentsOnDashboard missingInfo={false} />
            </Elements>
          )}
        </Grid.Col>
      </FeatureAccessGate>
    </>
  )
}

export default observer(CoachDashboard)
