import { useMutation } from '@apollo/client';
import { EUserTypes } from 'components/User/userTypes';
import { DEFAULT_LIMIT } from 'components/constants';
import { Formik } from 'formik';
import { DELETE_USER_NOTE } from 'graphql/DELETE_USER_NOTE';
import { EDIT_USER_NOTE } from 'graphql/EDIT_USER_NOTE';
import { GET_USER_NOTES } from 'graphql/GET_USER_NOTES';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Form } from 'tabler-react';
import * as Yup from 'yup';
import { ADD_USER_EVENT } from '../../../graphql/ADD_USER_EVENT';
import { EUserEventsId } from '../../User/UserEvents';
import { GET_ALL_USER_REGISTRATION_EVENTS } from '../../../graphql/GET_ALL_USER_REGISTRATION_EVENTS';
import { Types } from '../../../types/graphql'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'

const addUserNoteValidationSchema = Yup.object().shape({
  note: Yup.string().required('This field is required.')
});

interface IStudentNoteForm {
  studentNote: Types.UserNote;
  setEditting: (editting: boolean) => void;
  filter: {
    student_id: number;
    registration_id: number;
    team_id: number;
    limit: number;
    offset: number;
  };
}

const StudentNoteForm = ({
  studentNote,
  setEditting,
  filter
}: IStudentNoteForm) => {
  const { currentUser } = useRootStore();
  const [note, setNote] = useState(studentNote.note);
    const { isAdminGroup } = useGetCurrentUserType()
  const { studentId } = useParams<{ studentId: string }>();
  const [userEvent] = useMutation(ADD_USER_EVENT);
  const [editUserNote] = useMutation(EDIT_USER_NOTE, {
    onCompleted: (result) => {
      if (result.updateUserNote) {
        userEvent({
          variables: {
            userEvent: {
              user_event_type_id: EUserEventsId.unote_update,
              student_id: studentNote.student_id,
              status: note,
              team_id: studentNote.team_id,
              user_registration_id: Number(studentNote.registration_id)
            }
          },
          refetchQueries: [
            {
              query: GET_ALL_USER_REGISTRATION_EVENTS,
              variables: {
                filter: {
                  userRegistrationId: studentNote.registration_id
                },
                limit: 20,
                page: 1
              }
            }
          ]
        });
        toast.success('User note updated!');
      }
    }
  });

  const [deleteUserNote] = useMutation(DELETE_USER_NOTE, {
    onCompleted: (result) => {
      if (result.deleteUserNote) {
        userEvent({
          variables: {
            userEvent: {
              user_event_type_id: EUserEventsId.unote_delete,
              student_id: studentNote.student_id,
              status: 'Deleted: ' + note,
              team_id: studentNote.team_id,
              user_registration_id: Number(studentNote.registration_id)
            }
          }
        });
        toast.success('User note removed!');
      }
    }
  });

  const deleteNote = () => {
    if (window.confirm('Delete user note?')) {
      deleteUserNote({
        variables: {
          id: studentNote.id
        },
        refetchQueries: [
          { query: GET_USER_NOTES, variables: { userNoteOptions: filter } }
        ]
      });
    }
  };

  const initValues = {
    id: studentNote.id,
    note: studentNote.note
  };

  const renderForm = ({ values, handleSubmit, handleChange, errors }) => (
    <>
      <Form.Textarea
        name="note"
        placeholder="Add notes"
        rows={3}
        type="text"
        onChange={handleChange}
        value={values.note}
      />
      {errors.note && (
        <span className="field-error text-danger">{errors.note}</span>
      )}
      <Button.List className="mt-4" align="right">
        {(currentUser.id === studentNote.coach.id || isAdminGroup) && (
          <Button
            className={'float-left'}
            pill
            color="secondary"
            size="sm"
            onClick={() => deleteNote()}
          >
            DELETE
          </Button>
        )}
        {/*
        <Button
          pill
          icon="x"
          color="white"
          size="sm"
          onClick={() => setEditting(false)}
        >
          EDIT
        </Button>
        */}
        <Button
          pill
          color="primary"
          size="sm"
          type="submit"
          onClick={() => handleSubmit()}
        >
          SAVE
        </Button>
      </Button.List>
    </>
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={addUserNoteValidationSchema}
        initialValues={initValues}
        onSubmit={async (values, onSubmitHelpers) => {
          setNote(values.note);
          onSubmitHelpers.setFieldValue('note', ' ');
          await editUserNote({
            variables: {
              updateUserNoteInput: {
                id: values.id,
                note: values.note
              }
            },
            refetchQueries: [
              {
                query: GET_USER_NOTES,
                variables: {
                  userNoteOptions: {
                    student_id: Number(studentId),
                    limit: DEFAULT_LIMIT,
                    offset: 0
                  }
                }
              }
            ]
          });
          onSubmitHelpers.resetForm({ values: initValues });
          setEditting(false);
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    </>
  );
};

export default observer(StudentNoteForm);
