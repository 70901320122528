import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ORGS } from '../../graphql/GET_ORGS'
import { Button, Card, Form, Table } from 'tabler-react'
import OrgFormModal from './Modals/OrgFormModal'
import OrgListItem from './OrgListItem'
import { Types } from '../../types/graphql'

const OrgList = () => {
  const { data } = useQuery(GET_ORGS)
  const [isOpen, setIsOpen] = useState(false)
  const [nameFilter, setNameFilter] = useState('')
  const orgs = useMemo(() => {
    if (data) {
      return data.orgs.filter((org: Types.Org) =>
        org?.name.toLowerCase().includes(nameFilter.toLowerCase()) ||
        org?.slug.toLowerCase().includes(nameFilter.toLowerCase())
      )
    }
    return []
  }, [data, nameFilter])
  return (
    <Card>
      <Card.Header>
        <Card.Title>Organizations</Card.Title>
        <Card.Options>
          <Button
            icon="plus"
            color={'primary'}
            outline
            className="mr-2 float-right"
            onClick={() => setIsOpen(true)}
          />
          <Form.Input
            autoFocus
            type="search"
            placeholder="Search..."
            name="search"
            value={nameFilter}
            onChange={(e) => {
              setNameFilter(e.target.value)
              e.persist()
            }}
          />
        </Card.Options>
      </Card.Header>

      <Card.Body>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>ID</Table.ColHeader>
              <Table.ColHeader></Table.ColHeader>
              <Table.ColHeader>Name</Table.ColHeader>
              <Table.ColHeader>Country</Table.ColHeader>
              <Table.ColHeader>Type</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orgs.map((org) => (
              <OrgListItem key={org.id} org={org} />
            ))}
          </Table.Body>
        </Table>
        <OrgFormModal
          org={null}
          isOpen={isOpen}
          toggle={() => setIsOpen(false)}
        />
      </Card.Body>
    </Card>
  )
}

export default OrgList
