import React from 'react'
import { Icon } from 'tabler-react'
import Modal from '../Modal'

type InformationModalProps = {
  title: string
  content: string | JSX.Element
}
const InformationModal = ({ title, content }: InformationModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleModal = () => setIsOpen(!isOpen)
  return (
    <>
      <Icon name={'help-circle'} onClick={toggleModal} />
      <Modal
        open={isOpen}
        title={title}
        content={content}
        onClose={toggleModal}
      />
    </>
  )
}

export default InformationModal
