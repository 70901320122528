import gql from 'graphql-tag'

export const GET_REGISTRATION_GROUPS = gql`
  query GET_REGISTRATION_GROUPS($registrationId: ID!) {
    getParticipantCampRegistrationGroups(registrationId: $registrationId) {
      id
      name
      camp_id
      discipline_id
      discipline
      team_id
      seq
      sport_type_id
      group_code
      user_registration_id
      image
      status
      color
      paymentDetails {
        currency
        outstanding
        total
        totalPaid
        totalFees
        totalComp
        payments {
          id
          amount
          total
          card_brand
          created_on
          currency
          description
          manual_payment
          status
        }
      }
      members {
        first_name
        last_name
        id
        email
        phone_number
        phone_country_code
        profile_avatar
        roleName
        roleId
      }
      group_options {
      id
      registration_option_id
      name
      option_value
      quantity
      fee_type
    }

  }
  }
`
