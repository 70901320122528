import gql from 'graphql-tag'

export const GET_CAMP_BY_ID = gql`
  query GET_CAMP_BY_ID($campId: Int!) {
    getCampById(campId: $campId) {
      id
      all_day
      auto_approval
      camp_description
      camp_email
      camp_end_time
      camp_fee_type_id
      camp_fees
      camp_image
      camp_name
      camp_phone
      camp_phone_country_code
      camp_start_time
      camp_type_id
      camp_url
      camp_whatsapp
      conditional_students_count
      created_by
      created_on
      default_jump_count
      default_jump_price
      deposit_fee
      end
      event_type_id
      event_type_name
      is_public
      is_published
      location
      location_id
      org_id
      paypal
      pending_students_count
      registration_currency
      registered_students_count
      registration_information
      registration_list
      registration_status
      registration_type
      registration_waitlist
      registrations_max
      rejected_students_count
      registration_fee
      registration_groups
      sanction_fee
      sanction_fee_currency
      score_cards
      slot_duration
      sport_type_id
      sport_type_name
      start
      status
      student_registration
      student_slots
      students_per_slot
      students_per_slot
      teamCurrency
      team_id
      team_name
      team_slug
      updated_by
      updated_on
      venmo
      waitlist_students_count
      whatsapp_group
      org {
        id
        slug
        name
      }
      coHosts {
        id
        name
      }

      registered_students {
        id
        camp_id
        created_on
        discipline
        first_name
        last_name
        phone_country_code
        profile_avatar
        registration_code
        status
        status_color
        status_icon
        status_type
        student_id
        user_registrations_id
      }
    }
  }
`
