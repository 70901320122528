import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GET_GROUPS } from '../../../graphql/GET_GROUPS'
import { GET_STRIPE_PUBLIC_KEY } from '../../../graphql/GET_STRIPE_PUBLIC_KEY'
import {
  GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE
} from '../../../graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID'
import { GET_PAYMENT_FEE_BY_TEAM_ID } from '../../../graphql/GET_PAYMENT_FEE_BY_TEAM_ID'
import { Loading } from '../../../components/Loading'
import { Card, Dimmer, Grid } from 'tabler-react'
import CompetitionGroupsListItem from '../../../components/Calendar/CompetitionGroupsListItem'
import GroupFinanceItem from './GroupFinanceItem'
type GroupFinanceProps = {
  campId: string
  teamId: string
}
const GroupFinance = ({campId, teamId}:GroupFinanceProps) => {
  const { data, loading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        camp_id: Number(campId),
        team_id: teamId,
        status: 'Active'
      }
    }
  })

  const groupList = useMemo(() => {
    if (data) {
      return data.groupList
    }

    return []
  }, [data?.groupList])
  const { loading: loading1, data: stripeData } = useQuery(
    GET_STRIPE_PUBLIC_KEY
  )

  const stripeKey = useMemo(
    () => stripeData?.getStripePublicKey,

    [stripeData?.getStripePublicKey]
  )

  const { loading: loading2, data: paymentData } =
    useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(GET_PAYMENT_FEE_BY_TEAM_ID, {
      variables: {
        teamId: Number(teamId)
      }
    })

  const paymentFee =
    paymentData?.getPaymentFeeByTeamId.payment_fee_customer / 100

  if (loading1 || loading2) {
    return <Loading />
  }
  return (
    <Card.Body>
      <Dimmer active={loading} loading={loading}>
        {groupList && (
          <Grid.Row>
            {groupList.map((group) => (
              <Grid.Col md={4} key={group.id}>
                <div>
                  <GroupFinanceItem
                    group={group}
                    stripeKey={stripeKey}
                    paymentFee={paymentFee}
                  />
                </div>
              </Grid.Col>
            ))}
          </Grid.Row>
          )}
      </Dimmer>
    </Card.Body>
  )
}

export default GroupFinance
