import { useMutation, useQuery } from '@apollo/client'
import { UserStoreContext } from 'contexts/userStoreContext'
import { GET_SPORT_TYPES } from 'graphql/GET_SPORT_TYPES'
import { GET_SPORTS_BY_STUDENT_ID } from 'graphql/GET_SPORTS_BY_STUDENT_ID'
import { INSERT_DELETE_USER_SPORT } from 'graphql/INSERT_DELETE_USER_SPORT'
import { GET_SPORT_TYPES as GET_SPORT_TYPES_TYPE } from 'graphql/types/GET_SPORT_TYPES'
import { GET_SPORTS_BY_STUDENT_ID as GET_SPORTS_BY_STUDENT_ID_TYPE } from 'graphql/types/GET_SPORTS_BY_STUDENT_ID'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import {
  SPORT_TYPE_LOCATION_MAPPING,
  SportRowType
} from 'modules/sport-type/constants'
import { LocationType } from 'modules/team/constants/api'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Card, Dimmer, Text } from 'tabler-react'
import { domainActivities } from '../constants'

const UserActivitiesList = () => {
  const { user } = useContext(UserStoreContext)
  const { currentUser } = useRootStore()

  const { isAdmin } = useGetCurrentUserType()
  const { domainData } = useRootStore()
  const { data: studentSports } = useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(
    GET_SPORTS_BY_STUDENT_ID,
    {
      variables: {
        student_id: isAdmin ? user.id : currentUser?.id
      }
    }
  )

  const studentSportIds = useMemo(
    () =>
      studentSports?.getSportsByStudentId
        .filter((sport) => sport.is_active)
        .map((sport) => sport.id) ?? [],
    [studentSports?.getSportsByStudentId]
  )

  const [majorActivityIds, setMajorActivityIds] = useState<number[]>([])
  const [activities, setActivities] = useState<
    {
      sportTypeId: number
      locationType: LocationType
    }[]
  >([])

  const [insertDeleteUserSports] = useMutation(INSERT_DELETE_USER_SPORT, {
    onCompleted: () => {
      toast.success('Activities updated successfully')
    }
  })

  // If activities length is greater than 0, set the majorActivityIds to the activities
  useEffect(() => {
    if (activities.length > 0) {
      setMajorActivityIds(activities.map((activity) => activity.sportTypeId))
    }
  }, [activities])

  const { data: sportTypesData, loading: sportTypesLoading } =
    useQuery<GET_SPORT_TYPES_TYPE>(GET_SPORT_TYPES)

  const sportTypeRows = useMemo(
    () =>
      sportTypesData?.getSportTypes
        .filter((sportType) => sportType.sub === null)
        ?.map(({ id, name, slug, sub }) => ({
          id,
          name,
          slug,
          sub,
          hasSub: sportTypesData?.getSportTypes.some(
            (sportType) => sportType.sub === id
          )
        }))
        .filter(
          (sportType) =>
            sportType.sub === null &&
            domainActivities[domainData.key].includes(sportType.id)
        ) ?? [],
    [sportTypesData?.getSportTypes]
  )

  const subSportTypeRows = useMemo(() => {
    if (majorActivityIds.length === 0) {
      return []
    }

    return majorActivityIds.flatMap(
      (majorActivityId) =>
        sportTypesData?.getSportTypes
          .filter((sportType) => sportType.sub === majorActivityId)
          ?.map(({ id, name }) => ({
            id,
            name,
            hasSub: sportTypesData?.getSportTypes.some(
              (sportType) => sportType.sub === id
            )
          })) ?? []
    )
  }, [majorActivityIds, sportTypesData?.getSportTypes])

  useEffect(() => {
    if (studentSportIds.length > 0) {
      setActivities(
        studentSportIds.map((sportTypeId) => {
          const sub = sportTypesData?.getSportTypes.find(
            (sportType) => sportType.id === sportTypeId
          )?.sub

          const locationType = SPORT_TYPE_LOCATION_MAPPING.get(
            sub ?? sportTypeId
          ) as LocationType

          return {
            sportTypeId,
            locationType
          }
        })
      )
    }
  }, [studentSportIds, sportTypesData?.getSportTypes])

  const onSelectActivity = ({ id: sportTypeId, hasSub }: SportRowType) => {
    const sub = sportTypesData?.getSportTypes.find(
      (sportType) => sportType.id === sportTypeId
    )?.sub

    const locationType = SPORT_TYPE_LOCATION_MAPPING.get(
      sub ?? sportTypeId
    ) as LocationType

    const activityIndex = activities.findIndex(
      (activity) => activity.sportTypeId === sportTypeId
    )

    if (activityIndex === -1) {
      setActivities((prev) => [
        ...prev,
        {
          sportTypeId,
          locationType
        }
      ])

      setMajorActivityIds((prev) => {
        if (!hasSub) {
          return prev
        }

        if (prev.includes(sportTypeId)) {
          return prev.filter((id) => id !== sportTypeId)
        }

        return [...prev, sportTypeId]
      })
    } else {
      // filter activities by locationType
      const filteredActivities = activities.filter(
        (activity) => activity.locationType === locationType
      )

      if (hasSub && filteredActivities.length > 1) {
        return
      }

      setMajorActivityIds((prev) => prev.filter((id) => id !== sportTypeId))

      setActivities(
        activities.filter((activity) => activity.sportTypeId !== sportTypeId)
      )
    }

    insertDeleteUserSports({
      variables: {
        student_id: isAdmin ? Number(user.id) : currentUser?.id,
        sport_type_id: sportTypeId
      }
    })
  }

  return (
    <Card statusColor="blue" title="Activities">
      <Card.Body className="py-2">
        <Text.Small className="text-muted">
          Select all activites which you are interested in. Enabled activiites
          will make related content available such as coaching and events.
        </Text.Small>
        <Dimmer active={sportTypesLoading} loader={sportTypesLoading}>
          <Button.List className="mt-4 mb-3" align="center">
            {sportTypeRows.map((sportType) => {
              return (
                <Button
                  pill
                  color={
                    activities.some(
                      (activity) => activity.sportTypeId === sportType.id
                    )
                      ? 'primary'
                      : 'secondary'
                  }
                  size="sm"
                  key={sportType.id}
                  onClick={async (e) => {
                    e.preventDefault()

                    onSelectActivity(sportType)
                  }}
                >
                  {sportType.name}
                </Button>
              )
            })}
          </Button.List>
          {subSportTypeRows.length > 0 && (
            <Button.List align="center" className="my-2">
              {subSportTypeRows.map((sportType) => {
                return (
                  <Button
                    pill
                    color={
                      activities.some(
                        (activity) => activity.sportTypeId === sportType.id
                      )
                        ? 'info'
                        : 'secondary'
                    }
                    size="sm"
                    key={sportType.id}
                    onClick={(e) => {
                      e.preventDefault()

                      onSelectActivity(sportType)
                    }}
                  >
                    {sportType.name}
                  </Button>
                )
              })}
            </Button.List>
          )}
        </Dimmer>
      </Card.Body>
    </Card>
  )
}

export default observer(UserActivitiesList)
