import { useQuery } from '@apollo/client'
import StudentNoteForm from 'components/Students/Forms/StudentNoteForm'
import StudentNoteListItem from 'components/Students/StudentNoteListItem'
import { EUserTypes } from 'components/User/userTypes'
import { DEFAULT_LIMIT } from 'components/constants'
import { UserStoreContext } from 'contexts/userStoreContext'
import { GET_USER_NOTES } from 'graphql/GET_USER_NOTES'
import { GET_USER_NOTES as GET_USER_NOTES_TYPE } from 'graphql/types/GET_USER_NOTES'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { Card } from 'tabler-react'
import useGetCurrentUserType from "../../modules/common/hooks/useGetCurrentUserType";

interface IStudentNotesList {
  studentId?: number
  registrationId?: number
  teamId?: number
}

const StudentNotesList = ({
  studentId,
  registrationId,
  teamId
}: IStudentNotesList) => {
  const userStore = useContext(UserStoreContext)
  const { user } = userStore
  const rootStore = useRootStore()
  const { currentUser, currentCoachTeam } = rootStore
  const { isAdmin, isStudent } = useGetCurrentUserType();

  const filter = {
    student_id: Number(studentId) ?? parseInt(user.id),
    registration_id: Number(registrationId) ?? undefined,
    team_id: teamId ?? Number(currentCoachTeam?.id),
    limit: DEFAULT_LIMIT,
    offset: 0
  }

  const { data } = useQuery<GET_USER_NOTES_TYPE>(GET_USER_NOTES, {
    variables: {
      userNoteOptions: filter
    }
  })

  return (
    <>
      <Card.Body className="p-0 mb-4">
        {!isAdmin && !isStudent && (
          <StudentNoteForm
            studentNote={{
              student_id: studentId,
              registration_id: registrationId,
              team_id: teamId
            }}
            filter={filter}
          />
        )}
      </Card.Body>
      {data?.userNotes.map((userNote) => {
        if (currentUser.type === EUserTypes.student && !userNote.is_public) {
          return
        } else {
          return (
            <StudentNoteListItem
              userNote={userNote}
              filter={filter}
              key={userNote.id}
            />
          )
        }
      })}
    </>
  )
}

export default observer(StudentNotesList)
