import gql from 'graphql-tag'

export const GET_REGISTRATION_PAYMENTS = gql`
  query GET_REGISTRATION_PAYMENTS($user_registration_id: ID!) {
    getUserRegistrationPayments(user_registration_id: $user_registration_id) {
      id
      amount
      total
      fees
      processed_by
      manual_payment
      status
      created_on
      coach_first_name
      coach_last_name
    }
  }
`
