import { useMutation, useQuery } from '@apollo/client'
import { Formik } from 'formik'
import React, { useMemo } from 'react'
import ReactS3Uploader from 'react-s3-uploader'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Form, Icon } from 'tabler-react'
import * as Yup from 'yup'
import { ADD_GROUP } from '../../../graphql/ADD_GROUP'
import { DELETE_GROUP } from '../../../graphql/DELETE_GROUP'
import { EDIT_GROUP } from '../../../graphql/EDIT_GROUP'
import { GET_CAMP_DISCIPLINES } from '../../../graphql/GET_CAMP_DISCIPLINES'
import { useRootStore } from '../../../hooks'
import { Types } from '../../../types/graphql'
import CustomColorCheckItem from '../../CustomColorCheckItem'
import FormField from '../../FormField'
import Modal from '../../Modal'

interface IGroupFormModalProps {
  isOpen: boolean
  toggle: (i: boolean) => void
  campId?: string
  teamId?: string
  group?: Types.Group
}

const groupValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
})

const GroupFormModal = ({
  isOpen,
  toggle,
  campId,
  teamId,
  group
}: IGroupFormModalProps) => {
  const rootStore = useRootStore()
  const [addGroup] = useMutation(ADD_GROUP, {
    onCompleted: () => {
      toast.success('Group added successfully!')
    },
    refetchQueries: ['GET_GROUPS']
  })

  const [deleteGroup] = useMutation(DELETE_GROUP, {
    onCompleted: () => {
      toast.success('Group deleted')
    },
    refetchQueries: ['GET_GROUPS']
  })

  const [editGroup] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success('Group updated')
    },
    refetchQueries: ['GET_GROUPS']
  })

  const handleDeleteGroup = async (id) => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      await deleteGroup({ variables: { id } })
      toggle(false)
    }
  }

  const { data: disciplinesData, loading: disciplineLoading } = useQuery(
    GET_CAMP_DISCIPLINES,
    {
      variables: {
        campId: group?.camp_id ?? campId
      },
      skip: !group?.camp_id && !campId
    }
  )

  const disciplineOptions = useMemo(() => {
    if (!disciplineLoading && disciplinesData?.campDisciplines) {
      return (
        disciplinesData?.campDisciplines
          .filter((discipline) => discipline.isExists)
          .map((discipline) => ({
            label: discipline.name,
            value: discipline.disciplineId
          })) ?? []
      )
    }

    return []
  }, [disciplinesData, disciplineLoading])

  const onError = (error) => {
    toast.error(error)
    rootStore.setLoading(false)
  }

  const renderForm = ({
    handleChange,
    values,
    handleSubmit,
    setFieldValue
  }) => {
    return (
      <div>
        <Form.Group>
          <Form.Label>Group Name</Form.Label>
          <FormField
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </Form.Group>

        {disciplineOptions.length > 0 && (
          <Form.Group label="Discipline">
            <Select
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              options={disciplineOptions}
              name={'discipline_id'}
              onChange={(option) => {
                setFieldValue('discipline_id', Number(option.value))
              }}
              value={disciplineOptions.find(
                (i) => Number(i.value) === Number(values.discipline_id)
              )}
            />
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label>Color</Form.Label>
          <Form.ColorCheck className="ml-2">
            <CustomColorCheckItem
              color="azure"
              name="color"
              checked={values.color === 'azure'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              name="color"
              color="blue"
              checked={values.color === 'blue'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              name="color"
              color="indigo"
              checked={values.color === 'indigo'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color={'pink'}
              name="color"
              checked={values.color === 'pink'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color="red"
              name="color"
              checked={values.color === 'red'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color="orange"
              name="color"
              checked={values.color === 'orange'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color="yellow"
              name="color"
              checked={values.color === 'yellow'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color="lime"
              name="color"
              checked={values.color === 'lime'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color="green"
              name="color"
              checked={values.color === 'green'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color="teal"
              name="color"
              checked={values.color === 'teal'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color="secondary"
              name="color"
              checked={values.color === 'secondary'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
            <CustomColorCheckItem
              color="gray-dark"
              name="color"
              checked={values.color === 'gray-dark'}
              onChange={(color: string) => {
                setFieldValue('color', color)
              }}
            />
          </Form.ColorCheck>
          {/*<Form.Group>*/}
          {/*  <Form.Label>Sports type</Form.Label>*/}
          {/*  <FormField*/}
          {/*    type="text"*/}
          {/*    name="name"*/}
          {/*    value={values.name}*/}
          {/*    onChange={handleChange}*/}
          {/*  />*/}
          {/*</Form.Group>*/}
          <Form.Group className="ml-2">
            <label htmlFor="s3-upload" className="cursor-pointer mb-2 mt-3">
              <Icon name={'image'} className="mr-2" />
              {values.image !== null ? 'Change' : 'Upload'} Image
            </label>
            <ReactS3Uploader
              accept="image/*"
              autoUpload={true}
              className="d-none"
              contentDisposition="auto"
              id="s3-upload"
              signingUrl="/api/s3/sign"
              onError={onError}
              onFinish={(response) => setFieldValue('image', response.fileKey)}
              onProgress={() => rootStore.setLoading(true)}
              uploadRequestHeaders={{
                'x-amz-acl': 'private'
              }}
            />
            {values.image && (
              <div>
                <img
                  className={'d-flex mb-1 mt-2 float-left'}
                  src={`/api/s3/uploads/${values.image}`}
                  width={'100px'}
                  alt={'Groups'}
                />
                <Button
                  icon={'x'}
                  className={'d-inline ml-2 mt-3'}
                  color={'white'}
                  onClick={() => setFieldValue('image', null)}
                  size={'sm'}
                >
                  Remove Image
                </Button>
              </div>
            )}
          </Form.Group>
        </Form.Group>
        <Form.Group>
          {values.id && (
            <Button
              onClick={() => handleDeleteGroup(values.id)}
              color="danger"
              className="float-left"
              style={{ marginRight: '10px' }}
            >
              DELETE
            </Button>
          )}

          <Button
            onClick={handleSubmit}
            color="primary"
            className="float-right"
            style={{ marginRight: '10px' }}
          >
            SUBMIT
          </Button>
          <Button
            color="white"
            className="float-right mr-2"
            onClick={(e) => {
              e.preventDefault()
              toggle(false)
            }}
          >
            CANCEL
          </Button>
        </Form.Group>
      </div>
    )
  }
  return (
    <Modal
      open={isOpen}
      onClose={() => toggle(false)}
      title={group ? 'Edit Group' : 'Add Group'}
      content={
        <Formik
          validationSchema={groupValidationSchema}
          enableReinitialize={true}
          initialValues={{
            name: group?.name ?? '',
            id: group?.id ?? undefined,
            camp_id: group?.camp_id ?? Number(campId) ?? undefined,
            team_id: group?.team_id ?? Number(teamId),
            status: 'Active',
            seq: group?.seq ?? 0,
            sport_type_id: group?.sport_type_id ?? null,
            color: group?.color ?? null,
            group_code: group?.group_code ?? null,
            image: group?.image ?? null,
            discipline_id: group?.discipline_id ?? null
          }}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            if (!values.id) {
              await addGroup({
                variables: { addGroupInput: values }
              })
            } else {
              await editGroup({
                variables: { updateGroupInput: values }
              })
            }

            resetForm()
            setSubmitting(false)
            toggle(false)
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
    />
  )
}

export default GroupFormModal
