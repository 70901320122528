import { observer } from 'mobx-react'

import CoachDetails from 'components/Coaches/CoachDetails'
import Wrapper from '../layout'

const CoachDetailsPage = (props) => {
  return (
    <Wrapper {...props}>
      <div className="container">
        <CoachDetails />
      </div>
    </Wrapper>
  )
}

export default observer(CoachDetailsPage)
