import CalendarEventView from 'components/Calendar/CalendarEventView';
import { EventStoreProvider } from 'contexts/eventStoreContext';
import Wrapper from 'pages/layout';
import React from 'react';
import { Container } from 'tabler-react';
import { useRoles } from 'modules/common/hooks/useGetCurrentUserType'
import { Loading } from 'components/Loading';

const CalendarEventPage = (props) => {
  const { loading } = useRoles()

  if (loading) {
    return <Loading />
  }

  return (
    <Wrapper {...props}>
      <Container>
        <EventStoreProvider>
          <CalendarEventView />
        </EventStoreProvider>
      </Container>
    </Wrapper>
  );
};

export default CalendarEventPage;
