export default {
  control: (defaultStyles) => ({
    ...defaultStyles,
    border: '1px solid rgba(0, 40, 100, 0.12)'
  }),
  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      width: '16rem'
    }
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 })
}
