import gql from 'graphql-tag'

export const GET_FIGHTERS_SEARCH = gql`
  query GET_FIGHTERS_SEARCH($filter: FighterSearchInput!) {
    fighterSearch(filter: $filter) {
      fighters {
        id
        address1
        address2
        city
        country
        dob
        email
        first_name
        height
        is_disabled_on
        is_company
        jump_weight
        last_name
        middle_name
        phone_country_code
        phone_number
        postal_code
        preference_24h
        preference_units
        preferred_name
        profile_avatar
        profile_picture
        province
        registration_id
        shirt_size_id
        tunnel_time
        token
        total
        weight
        nac
        gender
        jump_count
        reach
        stance
      }
      totals {
        stance
        total
      }
      total
    }
  }
`
