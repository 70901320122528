import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { GET_PAYMENTS } from 'graphql/GET_PAYMENTS';
import { GET_PAYMENTS as GET_PAYMENTS_TYPE } from 'graphql/types/GET_PAYMENTS';
import { useRootStore } from 'hooks';
import React, { useState } from 'react';
import { Alert, Button, Card, Icon, Table } from 'tabler-react';
import { formatMoney } from 'utils/numberFormat';
import { Loading } from 'components/Loading';

const DEFAULT_LIMIT = 10;

const StudentPayments = ({ teamId }) => {
  const { currentUser } = useRootStore();
  const [page, setPage] = useState(0);
  const { data, loading, error } = useQuery<GET_PAYMENTS_TYPE>(GET_PAYMENTS, {
    variables: {
      params: {
        student_id: currentUser?.id,
        team_id: teamId,
        limit: DEFAULT_LIMIT,
        offset: page * DEFAULT_LIMIT
      }
    }
  });

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  const paymentRows = data?.getPayments.payments.map(
    ({
      id,
      amount,
      created_on,
      manual_payment,
      processed_by,
      status,
      team_name
    }) => ({
      id,
      amount,
      created_on,
      manual_payment,
      processed_by,
      status,
      team_name
    })
  ) ?? []

  const total = data?.getPayments.total

  return <StudentPaymentsInner paymentRows={paymentRows} total={total} page={page} setPage={setPage} />
}

const StudentPaymentsInner = ({ paymentRows, total, setPage, page }) => {
  return (
    <Card statusColor="gray-dark">
      <Card.Header>
        <Card.Title>Payments</Card.Title>
      </Card.Header>
      <Table className="card-table">
        <Table.Body>
          {paymentRows.length > 0 ? (
            paymentRows.map((payment) => (
              <Box
                display="flex"
                justifyContent="space-between"
                px={2}
                py={1}
              >
                <span>
                  {new Intl.DateTimeFormat('en-US').format(
                    new Date(payment.created_on)
                  )}
                  {payment.manual_payment && (
                    <span className="ml-2">{payment.manual_payment}</span>
                  )}
                  {payment.processed_by === 'Stripe' && (
                    <i className="fe fe-credit-card text-success ml-2" />
                  )}
                </span>
                <span>
                  {formatMoney(payment.amount)}
                  <span className="ml-2 status-icon bg-success" />
                  <span className="text-success">{payment.status}</span>
                </span>
              </Box>
            ))
          ) : (
            <Table.Row>
              <Table.Col colSpan="8">
                <Alert type="info text-center">
                  <strong>No Payments</strong>
                </Alert>
              </Table.Col>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {paymentRows.length > 0 && (
      <Card.Footer>
        <Box display="flex" justifyContent="flex-end">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gridGap={16}
          >
            <Button
              color="white"
              disabled={!page}
              onClick={() => setPage((prev) => prev - 1)}
            >
              <Icon name="chevron-left" className="mr-2 ml-0" />
            </Button>
            <span>{page + 1}</span>
            <Button
              color="white"
              disabled={page + 1 >= total / DEFAULT_LIMIT}
              onClick={() => setPage((prev) => prev + 1)}
            >
              <Icon name="chevron-right" className="mr-0 ml-2" />
            </Button>
          </Box>
        </Box>
      </Card.Footer>
      )}
    </Card>
  );
};

export default StudentPayments;
