import { useMutation } from "@apollo/client";
import FormField from "components/FormField";
import Modal from "components/Modal";
import { Formik, FormikValues } from "formik";
import { SEND_EMAIL_INVOICE } from "graphql/SEND_EMAIL_INVOICE";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid, Text } from "tabler-react";
import * as Yup from "yup";
import { Types } from "../../../../types/graphql";
import { useRootStore } from 'hooks';

// import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';

const emailInvoiceSchema = Yup.object().shape({
  invoiceId: Yup.number().required("This field is required."),
  studentEmail: Yup.string().required("This field is required."),
});

type Props = {
  invoiceId: number;
  invoice: Types.Invoice;
};

const SendEmailInvoiceButton = ({ invoiceId, invoice }: Props) => {
  const updateStudentEmailRef = useRef(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { domainData } = useRootStore()

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  //  const { isAdmin } = useGetCurrentUserType();

  const [sendEmailInvoice] = useMutation(SEND_EMAIL_INVOICE, {
    onCompleted: () => {
      toast.success("Invoice sent successfully");
    },
  });

  const emailInvoiceForm = ({ handleSubmit }: FormikValues) => (
    <form onSubmit={handleSubmit}>
      {invoice.student.email ? (
        <Grid.Row className="mb-4">
          <Grid.Col>
            <Form.Group className="mb-0">
              <FormField name="studentEmail" label="Email" type="email" />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      ) : (
        <div className="form-group">
          <Form.Checkbox
            label="Save email to account"
            className="float-right text-muted"
            onChange={() => {
              updateStudentEmailRef.current = !updateStudentEmailRef.current;
            }}
          />
          <FormField name="studentEmail" label="Email" type="email" />
        </div>
      )}

      {invoice.invoice_terms && (
        <Card>
          <Card.Body className="bg-light">
            <Text.Small muted>{invoice.invoice_terms}</Text.Small>
          </Card.Body>
        </Card>
      )}

      <Button
        icon="mail"
        color="primary"
        className="float-right mr-2"
        type="submit"
      >
        Email Invoice
      </Button>
      {invoice?.student.phone_country_code && invoice?.student.phone_number && (
        <Button
          link
          icon="message-circle"
          size="md"
          className="d-print-none mr-2"
          onClick={(e) => {
            e.preventDefault();
            window.location.href =
              "sms:" +
              invoice?.student.phone_country_code +
              invoice?.student.phone_number +
              "?body=" +
              "From: " +
              invoice?.company_name +
              " INV: " +
              invoice?.id +
              " https://" + (domainData.key === 'fairfights' ? 'app.fairfights.ca' : 'portal.skycru.com') +
              "/dzm/invoice/" +
              invoice?.id;
          }}
        >
          Send link by SMS instead
        </Button>
      )}
    </form>
  );

  return (
    <>
      <Button
        icon="mail"
        size="md"
        color="primary"
        className="d-print-none"
        onClick={toggleModal}
        //  disabled={!isAdmin}
      >
        SEND
      </Button>
      <Modal
        content={
          <Formik
            enableReinitialize={true}
            initialValues={{
              invoiceId,
              studentEmail: invoice.student.email,
            }}
            validationSchema={emailInvoiceSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { invoiceId, studentEmail } = values;

              try {
                await sendEmailInvoice({
                  variables: {
                    invoiceId,
                    studentEmail,
                    updateStudentEmail: updateStudentEmailRef.current,
                  },
                });
              } catch (error) {
                toast.error(error);
              }

              setSubmitting(false);
              resetForm();

              toggleModal();
            }}
          >
            {(formikData) => emailInvoiceForm(formikData)}
          </Formik>
        }
        open={isModalOpen}
        onClose={toggleModal}
        title="Send Invoice"
      />
    </>
  );
};

export default SendEmailInvoiceButton;
