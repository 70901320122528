import React from 'react';
import { Button, Text } from 'tabler-react';
import { EHostnames } from 'components/constants'
import { useRootStore } from '../hooks'
import Wrapper from 'pages/layout';
import { IconBrandWhatsapp, IconBrandFacebook } from '@tabler/icons-react'

const HelpPage = (props) => {
  const { domainData } = useRootStore()

  return (
    <Wrapper {...props} title="Help">
      <h1 className="container">Help & Support</h1>
      {domainData?.key === EHostnames.SKYCRU && (
        <>
          <div className="container">
            <Text>
              Create Support Ticket:{' '}
              <a
                href="mailto:tickets@skycru.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                tickets@skycru.com
              </a>
            </Text>
            <Text>
              Email Support:{' '}
              <a
                href="mailto:help@skycru.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                help@skycru.com
              </a>
            </Text>
            <Text className="mt-3">
            <Button
              color="primary"
              onClick={() => window.open('https://www.facebook.com/skycru/', '_blank', 'noopener,noreferrer')}
              className="mr-3"
            >
              <IconBrandFacebook size={14} className="mr-1" />
              Facebook Support
            </Button>
            <Button
              color="success"
              onClick={() => window.open('https://wa.me/18582259761', '_blank', 'noopener,noreferrer')}
            >
              <IconBrandWhatsapp size={14} className="mr-1" />
              WhatsApp Support
            </Button>
            </Text>
            <Text className="mt-7">
              <a
                href="https://www.skycru.com/meetings/marshall43"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a> to schedule a Demo, Training Session, or other Meeting
            </Text>
          </div>
          <div className="container mt-5">
            <Text>
              Search the{' '}
              <a
                href="https://help.skycru.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                SKYCRU User Knowledge Base
              </a>
              {' '}for tutorials and commonly asked questions!
            </Text>
          </div>
          <div className="container mt-5">
            <Text>
              Join the{' '}
              <a
                href="https://x.com/i/communities/1703409688359145857"
                target="_blank"
                rel="noopener noreferrer"
              >
                SKYDIVING community
              </a>
              {' '}on X!
            </Text>
          </div>
        </>
      )}
      {domainData?.key === EHostnames.FAIRFIGHTS && (
        <>
          <div className="container">
            <Text>
              Create Support Ticket:{' '}
              <a
                href="mailto:tickets@fairfights.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                tickets@fairfights.ca
              </a>
            </Text>
            <Text>
              Email Support:{' '}
              <a
                href="mailto:help@fairfights.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                help@fairfights.ca
              </a>
            </Text>
            <Text>
              Facebook Page:{' '}
              <a
                href="https://www.facebook.com/fairfights/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAIR FIGHTS
              </a>
            </Text>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default HelpPage;
