import { useMutation, useQuery } from '@apollo/client'
import EventGroupsListItem from 'components/Calendar/EventGroupsListItem'
import GroupFormModal from 'components/Calendar/Modal/GroupFormModal'
import { ADD_STUDENT_TO_GROUP } from 'graphql/ADD_STUDENT_TO_GROUP'
import { GET_GROUPS } from 'graphql/GET_GROUPS'
import { GET_REGISTERED_STUDENTS } from 'graphql/GET_REGISTERED_STUDENTS'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Card, Dimmer, Grid } from 'tabler-react'
import { DELETE_STUDENT_FROM_GROUP } from '../../graphql/DELETE_STUDENT_FROM_GROUP'
import { Types } from '../../types/graphql'

interface EventGroupsListProps {
  campId: string
  teamId: string
  campName: string
  students: Types.CampStudents[]
}

const EventGroupsList = ({
  campId,
  teamId,
  campName,
  students
}: EventGroupsListProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data, loading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        camp_id: Number(campId),
        team_id: teamId,
        status: 'Active'
      }
    }
  })

  const groupList = useMemo(() => {
    if (data) {
      return data.groupList
    }

    return []
  }, [data?.groupList])

  const [addStudentToGroup] = useMutation(ADD_STUDENT_TO_GROUP, {
    onCompleted: () => {
      toast.success('User added to group')
    }
  })
  const [removeStudent] = useMutation(DELETE_STUDENT_FROM_GROUP)

  const handleDrag = (e, studentId) => {
    e.dataTransfer.setData('studentId', studentId)
  }

  const handleDrop = (e, groupId) => {
    const group = data.groupList.find((group) => group.id === groupId)
    const studentId = e.dataTransfer.getData('studentId')
    if (group.members.find((member) => member.id === studentId)) {
      toast.error('User already in group')
    } else {
      addStudentToGroup({
        variables: {
          addGroupAssociatedUsersInput: {
            group_id: groupId,
            student_id: e.dataTransfer.getData('studentId')
          }
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(campId),
                team_id: teamId,
                status: 'Active'
              }
            }
          },
          {
            query: GET_REGISTERED_STUDENTS,

            variables: {
              campId: Number(campId)
            }
          }
        ]
      })
      if (e.dataTransfer.getData('action') === 'transfer') {
        removeStudent({
          variables: {
            groupId: e.dataTransfer.getData('previousGroupId'),
            studentId: studentId
          },
          refetchQueries: [
            {
              query: GET_GROUPS,
              variables: {
                filter: {
                  camp_id: Number(group.camp_id),
                  team_id: Number(group.team_id),
                  status: 'Active'
                }
              }
            },
            {
              query: GET_REGISTERED_STUDENTS,

              variables: {
                campId: Number(campId)
              }
            }
          ]
        })
      }
    }
  }

  const handleDragover = (e) => {
    e.preventDefault()
  }

  return (
    <Card.Body>
      <Dimmer active={loading} loading={loading}>
        {data && (
          <>
            <Grid.Row>
              <Grid.Col md={3} lg={2}>
                <Button
                  block
                  icon="plus"
                  color="secondary"
                  className="mb-5"
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  GROUP
                </Button>
                {groupList.length > 0 && students.length > 0 && (
                  <div>
                    <h4>Participants</h4>
                    <div className="overflow-scrolly pr-1">
                      {students.map((student) => {
                        // Check if student is in multiple groups
                        // If so, display indicator icon
                        const studentGroups = groupList.filter((group) =>
                          group.members.some(
                            (member) => member.id === student.student_id
                          )
                        )
                        return (
                          <div
                            draggable
                            onDragStart={(e) =>
                              handleDrag(e, student.student_id.toString())
                            }
                            key={student.student_id}
                          >
                            <Button
                              block
                              outline={studentGroups.length === 0}
                              icon={
                                studentGroups.length === 1
                                  ? 'user'
                                  : studentGroups.length > 1
                                  ? 'users'
                                  : 'user-x'
                              }
                              color={
                                studentGroups.length > 1 ? 'info' : 'primary'
                              }
                              size="sm"
                              className="mb-2 text-left"
                            >
                              {`${student.first_name} ${student.last_name}`}
                            </Button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </Grid.Col>
              <Grid.Col md={10}>
                <Grid.Row>
                  {groupList.map((group) => (
                    <Grid.Col md={4} key={group.id}>
                      <div
                        onDrop={(e) => handleDrop(e, group.id)}
                        onDragOver={handleDragover}
                      >
                        <EventGroupsListItem
                          group={group}
                          groupList={groupList}
                        />
                      </div>
                    </Grid.Col>
                  ))}
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            <GroupFormModal
              isOpen={isModalOpen}
              toggle={setIsModalOpen}
              campId={campId}
              teamId={teamId}
            />
          </>
        )}
      </Dimmer>
    </Card.Body>
  )
}

export default EventGroupsList
