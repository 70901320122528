import { useMutation } from '@apollo/client'
import { applySnapshot } from 'mobx-state-tree'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { Dropdown } from 'tabler-react'
import useReactRouter from 'use-react-router'
import UserCardDropdown from '../../../../../components/User/UserCardDropdown'
import { EUserTypes } from '../../../../../components/User/userTypes'
import { UserStoreContext } from '../../../../../contexts/userStoreContext'
import { UPDATE_STUDENT_MUTATION } from '../../../../../graphql/UPDATE_STUDENT_MUTATION'
import { UPDATE_USER_MUTATION } from '../../../../../graphql/UPDATE_USER_MUTATION'
import { useRootStore } from '../../../../../hooks'
import {
  useGetUserIsAdmin,
  useGetUserIsCoach,
  useGetUserIsCoachSuperAdmin,
  useGetUserIsStudent
} from '../../../../common/hooks/useGetCurrentUserType'

type AvatarUploadProps = {
  profileAvatar?: string
  source?: string
  refetch?: any
  studentId?: number
  className?: string
  refetchQueries?: string[]
}

const AvatarUpload = ({
  profileAvatar,
  source,
  refetch,
  studentId,
  className,
  refetchQueries
}: AvatarUploadProps) => {
  const userStore = useContext(UserStoreContext)
  const rootStore = useRootStore()
  const { user } = userStore
  const { currentUser, coach, coaches } = rootStore
  const { id, type } = currentUser
  const typeOverride = source === 'admin' ? EUserTypes.coach : type
  const idOverride = source === 'admin' ? coach?.id : id

  const { history, location } = useReactRouter()
  const isStudent = useGetUserIsStudent()
  const isCoach = useGetUserIsCoach()
  const isAdmin = useGetUserIsAdmin()
  const isCoachSuperAdmin = useGetUserIsCoachSuperAdmin()

  const adminToUpdateStudent: boolean =
    !isStudent && location.pathname === '/user-details'

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success('Avatar Updated.')
  })

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => toast.success('Avatar Updated.')
  })

  const onError = (error) => {
    toast.error(error)
    rootStore.setLoading(false)
  }

  const onFinished = (response: { fileKey: string }) => {
    updateAvatar(response.fileKey)
    rootStore.setLoading(false)
  }

  const updateAvatar = async (fileKey: string | null) => {
    if (isCoach && location.pathname.includes('/user/calendar')) {
      await updateStudent({
        variables: {
          student: {
            id: studentId,
            profile_avatar: fileKey
          }
        }
      })
      if (location.pathname.includes('/user/calendar')) {
        refetch()
      }
    } else if (isStudent || adminToUpdateStudent) {
      await updateStudent({
        variables: {
          student: {
            id: adminToUpdateStudent ? user.id : id,
            profile_avatar: fileKey
          }
        },
        refetchQueries: refetchQueries ?? []
      })
      if (location.pathname.includes('/user/calendar')) {
        refetch()
      }
    } else {
      await updateUser({
        variables: {
          user: {
            id: idOverride,
            profile_avatar: fileKey,
            type: typeOverride
          }
        }
      })
    }

    if (adminToUpdateStudent) {
      applySnapshot(user, {
        ...user,
        profile_avatar: fileKey
      })
    } else {
      if (source === 'admin') {
        rootStore.updateCoach({ ...coach, profile_avatar: fileKey })
      } else {
        applySnapshot(currentUser, {
          ...currentUser,
          profile_avatar: fileKey
        })
      }
    }
  }
  return (
    <Dropdown
      className={`cursor-pointer avatar-dropdown ${className}`}
      icon="camera"
      items={
        <UserCardDropdown
          rootStore={rootStore}
          imageName={profileAvatar}
          onErrorHandler={onError}
          onFinishedHandler={onFinished}
          callBack={updateAvatar}
        />
      }
      toggle={false}
    />
  )
}

export default AvatarUpload
