import gql from 'graphql-tag'

export const GET_ORGANIZER_COACHES_BY_EMAIL = gql`
  query GET_ORGANIZER_COACHES_BY_EMAIL($email: String!) {
    getOrganizerCoachByEmail(email: $email) {
      id
      first_name
      last_name
      email
      phone_number
      phone_country_code
      is_disabled_on
      registration_id
    }
  }
`
