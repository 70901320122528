import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { Button, Form, Grid, Text } from 'tabler-react'
import { GET_USER_REGISTRATIONS_BY_IDS } from '../../../graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { UPDATE_USER_REGISTRATION_OPTION } from '../../../graphql/UPDATE_USER_REGISTRATION_OPTION'
import { useRootStore } from '../../../hooks'
import { formatMoney } from '../../../utils/numberFormat'
import { EUserTypes } from '../userTypes'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'

interface IUserRegistrationFormProp {
  registrationOption: any
  registrationId: number
  handleToggleModal: any
  currency: string
}

const UserRegistrationOption = ({
  registrationOption,
  registrationId,
  handleToggleModal,
  currency
}: IUserRegistrationFormProp) => {
  const { currentUser } = useRootStore()
  const { isStudent } = useGetCurrentUserType()
  const [isEditting, setIsEditting] = useState<boolean>(false)
  const [quantity, setQuantity] = useState<number>(
    registrationOption?.quantity ?? 0
  )
  const [updateUserRegistrationOption] = useMutation(
    UPDATE_USER_REGISTRATION_OPTION
  )
  const onQuantityChange = async () => {
    await updateUserRegistrationOption({
      variables: {
        option: {
          id: Number(registrationOption.id),
          quantity: quantity
        }
      },
      refetchQueries: [
        {
          query: GET_USER_REGISTRATIONS_BY_IDS,
          variables: {
            userRegistrationsId: registrationId
          }
        }
      ]
    })
    setIsEditting(false)
  }

  const inputForm = () => {
    return (
      <Form.Input
        autoFocus
        placeholder="0"
        type="number"
        value={quantity}
        onChange={(e) => {
          let q = Number(e.target.value)
          if (q < 0) {
            q = 0
          }
          setQuantity(q)
        }}
        onBlur={onQuantityChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onQuantityChange()
          }
        }}
      />
    )
  }

  const inputButton = () => {
    return (
      <>
      {!isStudent ? (
      <Button
        color="secondary"
        onClick={() => {
          if (currentUser.type !== EUserTypes.student) setIsEditting(true)
        }}
      >
        {registrationOption?.quantity ?? '+'}
      </Button>
    ) : <h1 className='text-muted mt-1'>{registrationOption?.quantity > 0 && registrationOption?.quantity}</h1>}
    </>
    )
  }

  return (
    <Grid.Row>
      <Grid.Col width={8}>
        <p
          className="mb-0 cursor-pointer"
          onClick={() => {
            if (currentUser.type !== EUserTypes.student) handleToggleModal()
          }}
        >
          {registrationOption?.name}
        </p>
        <Text.Small className="text-muted">{`${formatMoney(
          Number(registrationOption?.option_value),
          currency
        )} ${registrationOption?.fee_type}`}</Text.Small>
      </Grid.Col>
      <Grid.Col width={2}>{isEditting ? inputForm() : inputButton()}</Grid.Col>
    </Grid.Row>
  )
}

export default UserRegistrationOption
