import { IconQrcode } from "@tabler/icons-react";
import Modal from "components/Modal";
import moment from "moment/moment";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router";
import { Avatar, Button, Text } from "tabler-react";
import { Types } from "types/graphql";
import { Loading } from "../../../../../components/Loading";
import { useRootStore } from 'hooks';

interface QRCodeUserRegistrationProps {
  userRegistration: Types.UserRegistrationsById;
}

const QRCodeUserRegistration = ({
  userRegistration,
}: QRCodeUserRegistrationProps) => {
  const { userRegId, studentId } = useParams<{
    userRegId: string;
    studentId: string;
  }>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { domainData } = useRootStore()

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (!userRegistration) return <Loading />;
  return (
    <>
      <Button
        block
        color="primary"
        className="d-flex justify-content-center mb-5"
        onClick={toggleModal}
      >
        <IconQrcode className="mr-2" /> QR Code
      </Button>
      <Modal
       // title="SCAN QR Code"
        content={
          <div className="text-center">
            <Text>
              {moment(userRegistration.start).locale(navigator.language).format('MMM D') + ' - '}
              {moment(userRegistration?.end).locale(navigator.language).format('MMM D')}

            </Text>
            <h3>{userRegistration.camp_name}</h3>
            <QRCode
              value={`https://${domainData.key === 'fairfights' ? 'app.fairfights.ca' : 'portal.skycru.com'}/events/registrations/${userRegId}/${studentId}`}
            />
            <div>
              <Avatar
                className="avatar mt-5"
                imageURL={
                  (userRegistration.profile_avatar &&
                    `/api/s3/uploads/${userRegistration.profile_avatar}`) ||
                  "//www.gravatar.com/avatar?d=mp"
                }
                size="xxl"
              />
            </div>
            <Text className="mt-5">
              <strong>{userRegistration.first_name} {userRegistration.last_name}</strong>
              <span className="ml-2 text-muted">{userRegistration.registration_code}</span>
            </Text>
            <Text className="text-muted">{domainData.key === 'skycru' ? 'SKY.D: ' : 'ID: '}{studentId}</Text>
          </div>
        }
        open={isModalOpen}
        onClose={toggleModal}
      />
    </>
  );
};

export default QRCodeUserRegistration;
