import { CoachEvents } from 'components/Coaches/CoachDetails'
import EditProfileForm from 'components/User/Forms/EditProfileForm'
import UserCredentialsForm from 'components/User/Forms/UserCredentialsForm'
import UserDzLocationForm from 'components/User/Forms/UserDzLocationForm'
import UserProfileForm from 'components/User/Forms/UserProfileForm'
import UserCard from 'components/User/Profile/UserCard'
import UserContactInfo from 'components/User/Profile/UserContactInfo'
import UserActivitiesList from 'components/User/UserActivitiesList'
import { UserStoreContext } from 'contexts/userStoreContext'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import { clone } from 'mobx-state-tree'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import useGetStudentById from 'modules/student/hooks/useGetStudentById'
import { useContext, useEffect, useState } from 'react'
import { Button, Card, Grid } from 'tabler-react'
import { EHostnames } from 'components/constants'

const UserSettings = () => {
  const userStore = useContext(UserStoreContext)
  const { currentUser, domainData } = useRootStore()
  const { isAdmin, isStudent, isCoachSuperAdmin } = useGetCurrentUserType()

  const { data, loading } = useGetStudentById(String(currentUser?.id))

  const userClone = clone(currentUser)
  const [display, setDisplay] = useState('settings')

  useEffect(() => {
    if (data?.getStudentById) {
      userStore.loadUser(data.getStudentById)
    }
  }, [data?.getStudentById])

  //  const hasAuthMerits = data?.getStudentById.auth_merits.length > 0;

  return (
    <Grid.Row>
      <Grid.Col lg={4} sm={12} xs={12}>
        {!isAdmin && (
          <UserCard
            firstName={currentUser?.first_name}
            lastName={currentUser?.last_name}
            profileAvatar={currentUser?.profile_avatar}
            profilePicture={currentUser?.profile_picture}
          />
        )}
        <UserContactInfo user={currentUser} userClone={userClone} />
        {isStudent && !loading && domainData?.key === EHostnames.SKYCRU && (
          <>
            <UserDzLocationForm user={data?.getStudentById} />
          </>
        )}
        {isStudent && <UserActivitiesList />}
      </Grid.Col>
      <Grid.Col lg={8} sm={12} xs={12}>
        {isStudent && (
          <Card>
            <UserProfileForm userProfile={data?.getStudentById} />
          </Card>
        )}
        {isCoachSuperAdmin && (
          <Grid.Row className="mb-2">
            <Grid.Col>
              <Button.List className="float-right">
                <Button
                  color={`${display === 'activity' ? 'primary' : 'white'}`}
                  onClick={() => setDisplay('activity')}
                >
                  Activity
                </Button>
                <Button
                  color={`${display === 'settings' ? 'primary' : 'white'}`}
                  onClick={() => setDisplay('settings')}
                >
                  Settings
                </Button>
              </Button.List>
            </Grid.Col>
          </Grid.Row>
          )}
          {display === 'activity' && (
            <Grid.Row>
              <Grid.Col>
                <CoachEvents coachId={currentUser.id} />
              </Grid.Col>
            </Grid.Row>
          )}
          {!isStudent && display === 'settings' && <EditProfileForm user={userClone} />}{' '}
        {isStudent && domainData.key === 'skycru' && <UserCredentialsForm />}
      </Grid.Col>
    </Grid.Row>
  )
}

export default observer(UserSettings)
