import { useQuery } from "@apollo/client";
import UserCredentialsForm from "components/User/Forms/UserCredentialsForm";
import UserCard from "components/User/Profile/UserCard";
import { GET_STUDENT_BY_ID } from "graphql/GET_STUDENT_BY_ID";
import { GET_STUDENT_BY_ID as GET_STUDENT_BY_ID_TYPE } from "graphql/types/GET_STUDENT_BY_ID";
import {
  useGetUserIsAdmin,
  useGetUserIsStudent,
} from "modules/common/hooks/useGetCurrentUserType";
import React, { useContext, useEffect, useMemo } from "react";
import { Button, Grid } from "tabler-react";
import useRouter from "use-react-router";

import { UserStoreContext } from "../../contexts/userStoreContext";
import { GET_RIGS } from "../../graphql/GET_RIGS";
import { GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID } from "../../graphql/GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID";
import { GET_USER_LOCATION } from "../../graphql/GET_USER_LOCATION";
import { useRootStore } from "../../hooks";
import UserSportsList from "../../modules/user/sports/components/UserSportsList";

const UserProfile = () => {
  const userStore = useContext(UserStoreContext);
  const rootStore = useRootStore();
  const { domainData } = useRootStore()
  const { currentUser } = rootStore;
  const isAdmin = useGetUserIsAdmin();
  const isStudent = useGetUserIsStudent();
  const { history } = useRouter();

  const { data: student } = useQuery<GET_STUDENT_BY_ID_TYPE>(
    GET_STUDENT_BY_ID,
    {
      variables: { student_id: currentUser.id },
      skip: !isStudent,
    },
  );

  const { data: userRigsData } = useQuery(GET_RIGS, {
    variables: {
      rigListFilter: {
        student_id: currentUser.id,
      },
    },
  });

  useEffect(() => {
    if (student) {
      userStore.loadUser(student.getStudentById);
    }
    // Eslint-disable-next-line
  }, [student]);

  const hasAuthMerits =
    student && student?.getStudentById.auth_merits.length > 0;

  const { data: sportsLocationsData } = useQuery(
    GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID,
    {
      variables: { student_id: currentUser.id },
    },
  );

  const { data: dataTunnelLocation, loading: tunnelLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: currentUser.id,
        flag_type_id: 6,
        location_type: "tunnel",
      },
    },
  );

  const defaultTunnelLocation = useMemo(() => {
    if (!tunnelLocationLoading && !!dataTunnelLocation?.getUserLocation) {
      return dataTunnelLocation.getUserLocation;
    }

    return null;
  }, [dataTunnelLocation, tunnelLocationLoading]);

  const { data: userLocation, loading: userLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: currentUser.id,
        flag_type_id: 6,
        location_type: "dropzone",
      },
    },
  );

  const defaultHomeLocation = useMemo(() => {
    if (!userLocationLoading && !!userLocation?.getUserLocation) {
      return userLocation.getUserLocation;
    }

    return null;
  }, [userLocation, userLocationLoading]);

  const profileComplete: boolean[] = [
    !!currentUser?.first_name && !!currentUser?.last_name,
    hasAuthMerits,
    sportsLocationsData?.getSportsByStudentId.length > 0,
    userRigsData?.rigList.length > 0,
    !!defaultTunnelLocation && !!defaultHomeLocation,
  ];

  const completionRate = useMemo(() => {
    const rate = Math.round(
      (profileComplete.filter(Boolean).length / profileComplete.length) * 100,
    );
    let color = "danger";

    switch (rate) {
      case 0:
        color = "danger";
        break;
      case 20:
        color = "danger";
        break;
      case 40:
        color = "warning";
        break;
      case 60:
        color = "warning";
        break;
      case 80:
        color = "info";
        break;
      case 100:
        color = "success";
        break;
      default:
        color = "danger";
    }

    return { rate, color };
  }, [profileComplete]);

  return (
    <Grid.Row>
      <Grid.Col lg={4} sm={12} xs={12}>
        {!isAdmin && (
          <UserCard
            firstName={currentUser?.first_name}
            lastName={currentUser?.last_name}
            profileAvatar={currentUser?.profile_avatar}
            profilePicture={currentUser?.profile_picture}
          />
        )}
        {!isStudent && !isAdmin && !currentUser?.profile_avatar && (
          <Button
            block
            icon="x-circle"
            color="danger"
            onClick={() => history.push("/user/settings")}
          >
            MISSING PHOTO
          </Button>
        )}
        {isStudent && completionRate.rate < 100 && (
          <Button
            block
            outline
            icon="check-circle"
            color={completionRate.color}
            className="mb-5"
            disabled
          >
            Profile {completionRate.rate}% Complete
          </Button>
        )}
        {isStudent && (
          <>
            {!currentUser?.first_name && !currentUser?.last_name && (
              <Button
                block
                outline
                icon={
                  currentUser?.first_name && currentUser?.last_name
                    ? "check-circle"
                    : "x-circle"
                }
                color={
                  currentUser?.first_name && currentUser?.last_name
                    ? "success"
                    : "danger"
                }
                className="mb-5"
                onClick={() => history.push("/user/settings")}
              >
                Name Missing
              </Button>
            )}

            {isAdmin && (
              <Button
                block
                outline
                icon={hasAuthMerits ? "check-circle" : "x-circle"}
                color={hasAuthMerits ? "success" : "danger"}
                className="mb-5"
                disabled
              >
                Skydiving Credentials
              </Button>
            )}
            {sportsLocationsData?.getSportsByStudentId.length < 1 && (
              <Button
                block
                outline
                icon={
                  sportsLocationsData?.getSportsByStudentId.length > 0
                    ? "check-circle"
                    : "x-circle"
                }
                color={
                  sportsLocationsData?.getSportsByStudentId.length > 0
                    ? "success"
                    : "danger"
                }
                className="mb-5"
                onClick={() => history.push("/user/settings")}
              >
                Select Activities
              </Button>
            )}
            {userRigsData?.rigList.length < 1 && domainData.key === 'skycru' && (
              <Button
                block
                outline
                icon={
                  userRigsData?.rigList.length > 0 ? "check-circle" : "x-circle"
                }
                color={userRigsData?.rigList.length > 0 ? "success" : "danger"}
                className="mb-5"
                onClick={() => history.push("/user/gear")}
              >
                Add Gear
              </Button>
            )}
            {!defaultTunnelLocation && !defaultHomeLocation && domainData.key === 'skycru' && (
              <Button
                block
                outline
                icon={
                  defaultTunnelLocation && defaultHomeLocation
                    ? "check-circle"
                    : "x-circle"
                }
                color={
                  defaultTunnelLocation && defaultHomeLocation
                    ? "success"
                    : "danger"
                }
                className="mb-5"
                onClick={() => history.push("/user/settings")}
              >
                Select Home DZ/Tunnel
              </Button>
            )}
          </>
        )}
        {isStudent && <UserSportsList />}
      </Grid.Col>
      <Grid.Col lg={8} sm={12} xs={12}>
        {/*<MeritCard />*/}
        {isStudent && domainData.key === 'skycru' && <UserCredentialsForm />}
      </Grid.Col>
    </Grid.Row>
  );
};

export default UserProfile;
