import { useLazyQuery, useQuery } from '@apollo/client'
import { UserStoreContext } from 'contexts/userStoreContext'
import { GET_STUDENT_BY_ID } from 'graphql/GET_STUDENT_BY_ID'
import { GET_STUDENT_BY_ID as GET_STUDENT_BY_ID_TYPE } from 'graphql/types/GET_STUDENT_BY_ID'
import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { GET_STUDENT_TEAM } from '../../graphql/GET_STUDENT_TEAM'
import { useRootStore } from '../../hooks'
import { useGetUserIsAdmin } from '../../modules/common/hooks/useGetCurrentUserType'

interface IStudentLinkProp {
  studentId?: number
  routeToStudent?: any
  studentName: string
}

const StudentLink = ({
  studentId,
  routeToStudent,
  studentName
}: IStudentLinkProp) => {
  const userStore = useContext(UserStoreContext)
  const { history } = useReactRouter()
  const { user } = userStore
  const { currentCoachTeam } = useRootStore()
  const isAdmin = useGetUserIsAdmin()

  const { data: studentTeamData, loading: studentTeamLoading } = useQuery(
    GET_STUDENT_TEAM,
    {
      variables: {
        student_id: Number(studentId),
        team_id: currentCoachTeam?.id
      },
      skip: !!isAdmin
    }
  )
  const studentTeam = useMemo(() => {
    if (!studentTeamLoading) return !!studentTeamData?.getStudentTeam

    return false
  }, [studentTeamData])

  const [getStudentData] = useLazyQuery<GET_STUDENT_BY_ID_TYPE>(
    GET_STUDENT_BY_ID,
    {
      onCompleted(data) {
        if (data) {
          userStore.detach(user)
          userStore.loadUser(data.getStudentById)
          history.push('/user-details')
        }
      }
    }
  )

  const alternateRouteToStudent = () => {
    getStudentData({
      variables: {
        student_id: studentId
      }
    })
  }

  return (
    <>
      <Link
        className="text-inherit"
        onClick={(e) => {
          e.preventDefault()
          if ((studentTeam && !studentTeamLoading) || isAdmin) {
            if (routeToStudent) {
              routeToStudent()
            } else {
              alternateRouteToStudent()
            }
          }

          return false
        }}
        to="#"
      >
        {studentName}
      </Link>
    </>
  )
}

export default StudentLink
