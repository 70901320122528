import { useQuery } from '@apollo/client'
import { GET_PAYMENTS } from 'graphql/GET_PAYMENTS'
import { GET_PAYMENTS as GET_PAYMENTS_TYPE } from 'graphql/types/GET_PAYMENTS'

type UseGetPaymentsProps = {
  date_filter?: string
  manual_payment?: boolean
  card_brand?: string
  group_id?: number
  camp_id?: number
  team_id?: number
}

const UseGetPayments = (props: UseGetPaymentsProps) => {
  const { data, loading } = useQuery<GET_PAYMENTS_TYPE>(GET_PAYMENTS, {
    variables: {
      params: props
    }
  })

  return { data, loading }
}

export { UseGetPayments }
