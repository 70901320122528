import React from 'react';
import { Container } from 'tabler-react';

import EventList from '../../components/Event/EventList';
import Wrapper from 'pages/layout';
import { EventStoreProvider } from '../../contexts/eventStoreContext';

const EventListPage = (props) => {
  return (
    <Wrapper {...props}>
      <Container>
        <EventStoreProvider>
          <EventList />
        </EventStoreProvider>
      </Container>
    </Wrapper>
  );
};
export default EventListPage;
