import { useMutation } from '@apollo/client'
import CurrencyInputField from 'components/CurrencyInputField'
import Modal from 'components/Modal'
import { Formik, FormikValues } from 'formik'
import { INSERT_PAYMENTS_BULK } from 'graphql/INSERT_PAYMENTS_BULK'
import { useRootStore } from 'hooks'
import { manualPaymentOptions } from 'hooks/manualPayments'
import { manualFormSchema } from 'modules/invoice/components/modals/InvoicePaymentModal'
import { EManualPaymentOpts } from 'modules/payment/constants/manualPaymentOptions'
import { EPaymentStatus } from 'modules/payment/constants/paymentStatus'
import { Button, Form, Grid } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'

export const BulkLogPaymentModal = ({
  isOpen,
  toggle,
  students,
  currency,
  teamId
}) => {
  const { currentUser } = useRootStore()
  const [bulkInsertPayments] = useMutation(INSERT_PAYMENTS_BULK, {
    refetchQueries: ['GET_REGISTERED_STUDENTS']
  })
  const renderManualForm = ({
    errors,
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue
  }: FormikValues) => (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={isSubmitting}>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <CurrencyInputField
              name="amount"
              value={values.amount}
              setFieldValue={setFieldValue}
              prefix="$"
              customInput={undefined}
              className="text-xxl text-center mb-3 border-bottom text-muted form-control"
            />
            <span className="field-error text-danger">
              {errors.total && errors.total}
            </span>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group className="mb-0">
              <Button
                icon={values.confirm_registrations ? 'check-square' : 'square'}
                color={'white'}
                size={'sm'}
                type={'button'}
                className={values.confirm_registrations ? 'text-success' : 'text-secondary'}
                onClick={() =>
                  setFieldValue(
                    'confirm_registrations',
                    !values.confirm_registrations
                  )
                }
              >
                Confirm Registrations
              </Button>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Select
                name="manual_payment"
                onChange={handleChange}
                className="text-uppercase"
              >
                {manualPaymentOptions(currency).map((paymentType) => (
                  <option value={paymentType.value} key={paymentType.value}>
                    {paymentType.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={6} lg={6}>
            <Button block color="primary" className="" type="submit">
              Log Payment of {formatMoney(values.amount, currency)}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </fieldset>
    </form>
  )
  return (
    <Modal
      title={`Log Payments: ${students.length} selected`}
      open={isOpen}
      onClose={toggle}
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            amount: 0,
            manual_payment: EManualPaymentOpts.cash,
            confirm_registrations: false
          }}
          validationSchema={manualFormSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const param = {
              ...values,
              currency,
              processed_by: 'Manual Payment',
              status:
                values.manual_payment === EManualPaymentOpts.comp
                  ? EPaymentStatus.comp
                  : EPaymentStatus.paid,
              created_by: currentUser.id,
              team_id: teamId,
              registrationIds: students.map(
                (student) => student.user_registrations_id
              )
            }
            await bulkInsertPayments({
              variables: {
                payments: param
              }
            })
            setSubmitting(false)
            toggle()
          }}
        >
          {(formikData) => renderManualForm(formikData)}
        </Formik>
      }
    />
  )
}
