import moment from 'moment/moment'
import React from 'react'
import { CSVLink } from 'react-csv'
import { Button, Dimmer, Icon } from 'tabler-react'
import { UseGetPayments } from '../../hooks/UseGetPayments'

const PaymentsCSV = ({ campId, filename, name }) => {
  const { data, loading } = UseGetPayments({
    camp_id: Number(campId)
  })

  let payments = []
  if (data?.getPayments && !loading) {
    payments =
      data?.getPayments.payments.map(
        ({
          id,
          amount,
          application_fee,
          card_brand,
          card_country,
          coach_first_name,
          coach_last_name,
          created_on,
          description,
          fees,
          first_name,
          last_name,
          last4,
          manual_payment,
          processed_by,
          team_name,
          total,
          group_name,
          status
        }) => ({
          customer:
            first_name && last_name
              ? `${first_name} ${last_name}`
              : group_name ?? '',
          submittedBy: coach_first_name
            ? `${coach_first_name} ${coach_last_name}`
            : 'Customer',
          teamName: team_name,
          paymentDate: moment(created_on).format('DD MMM YYYY h:mm a'),
          paymentMethod: manual_payment,
          description: description,
          processedBy: processed_by,
          cardBrand: card_brand,
          last4,
          cardCountry: card_country,
          total,
          applicatoinFee: application_fee,
          customerFee: fees,
          chargeAmount: amount,
          paymentId: id,
          status
        })
      ) ?? []
  }

  if (loading) {
    return (
      <Button loading color="secondary">
        Processing...
      </Button>
    )
  }

  return (
    <Dimmer active={loading} loader={loading}>
      {payments.length > 0 && (
        <CSVLink
          data={payments}
          filename={`${filename}.csv`}
          className="ml-0 text-decoration-none text-default"
        >
          <Icon name="download" className="mr-1" />
          {name}
        </CSVLink>
      )}
    </Dimmer>
  )
}

export default PaymentsCSV
