import { useLazyQuery, useMutation } from "@apollo/client";
import FormField from "components/FormField";
import { EUserTypes } from "components/User/userTypes";
import { UserStoreContext } from "contexts/userStoreContext";
import { Formik } from "formik";
import { GET_EMAIL_VALIDATION } from "graphql/GET_EMAIL_VALIDATION";
import { GET_JOB_LIST } from "graphql/GET_JOB_LIST";
import { GET_PHONE_VALIDATION } from "graphql/GET_PHONE_VALIDATION";
import { UPDATE_STUDENT_MUTATION } from "graphql/UPDATE_STUDENT_MUTATION";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import { applySnapshot, clone } from "mobx-state-tree";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import useResetPasswordByEmail from "modules/common/hooks/useResetPasswordByEmail";
import useSendActivationEmail from "modules/student/hooks/useSendActivationEmail";
import React, { useContext } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { Button, Card, Form } from "tabler-react";

const UserAccountForm = () => {
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const userClone = clone(user);

  const { isAdmin } = useGetCurrentUserType();

  const { currentUser, currentCoachTeam } = useRootStore();

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success("Profile Updated."),
  });

  const { resetPasswordByEmail } = useResetPasswordByEmail();
  const { sendActivationEmail } = useSendActivationEmail();

  const confirmResetPasswordByEmail = () => {
    if (window.confirm("Are you sure you want to reset password by email?")) {
      resetPasswordByEmail({
        variables: {
          email: user.email,
        },
      });
    }
  };

  // const confirmResetPasswordByPhone = () => {
  //   if (
  //     window.confirm(
  //       "Are you sure you want to reset student's password by sms?"
  //     )
  //   ) {
  //     userStore.resetPasswordByPhone();
  //   }
  // };

  const confirmSendActivationEmail = () => {
    if (window.confirm("Are you sure you want to send activation email?")) {
      sendActivationEmail({
        variables: {
          email: user.email,
        },
      });
    }
  };

  const filterOption =
    currentUser.type !== EUserTypes.admin
      ? { team_id: currentCoachTeam?.id }
      : {};

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_EMAIL_VALIDATION,
    { fetchPolicy: "network-only" },
  );
  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_PHONE_VALIDATION,
    { fetchPolicy: "network-only" },
  );

  const mstChangePhone = (e) => {
    e.preventDefault();
    const target = e.target;

    const phone = target.value.split(" ");
    const countryCode = phone.shift().replace(/\D/g, "");

    userClone.set("phone_number", phone.join("").replace(/\D/g, ""));
    userClone.set("phone_country_code", Number(countryCode));
  };

  const HandleValidateEmail = async (email) => {
    await validateEmail({
      variables: { email },
    });
  };

  const HandleValidatePhone = async (phone) => {
    await validatePhone({
      variables: { phone },
    });
  };

  return (
    <Formik
    id="accountUpdate"
    className="signin-update-form "
      enableReinitialize={true}
      initialValues={{
        id: userClone.id,
        phone_number: userClone.phone_number,
        phone_country_code: userClone.phone_country_code,
        email: userClone.email,
      }}
      onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
        let errorFound = false;

        if (emailData?.validateEmail) {
          setFieldError("email", "Email already exists. Contact SUPPORT.");
          errorFound = true;
        }

        if (phoneData?.validatePhone) {
          setFieldError(
            "phone_number",
            "Phone number already exists. Contact SUPPORT.",
          );
          errorFound = true;
        }

        if (errorFound) {
          setSubmitting(false);
          return false;
        }
        await updateStudent({
          variables: {
            student: {
              ...values,
            },
          },
          refetchQueries: [
            {
              query: GET_JOB_LIST,
              variables: {
                jobListFilter: {
                  ...filterOption,
                },
              },
            },
          ],
        });

        applySnapshot(user, {
          ...user,
          ...values,
        });
        setSubmitting(false);
        resetForm();
      }}
    >
      {({
        values,
        isSubmitting,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
      }) => {
        return (
          <>
            <Card.Body>
              <Form.Group label="Phone">
                <PhoneInput
                  inputProps={{
                    className: "form-control w-100",
                    name: "phone_number",
                    onChange: (e) => {
                      mstChangePhone(e);
                      const target = e.target;

                      const phone = target.value.split(" ");
                      const countryCode = phone.shift().replace(/\D/g, "");

                      setFieldValue(
                        "phone_number",
                        phone.join("").replace(/\D/g, ""),
                      );
                      setFieldValue("phone_country_code", Number(countryCode));
                    },
                    onBlur: async (e) => {
                      e.preventDefault();
                      await HandleValidatePhone(
                        e.target.value.split(" ").join("").replace(/\D/g, ""),
                      );
                    },
                  }}
                  value={`${values.phone_country_code} ${values.phone_number}`}
                />
                <span className="field-error text-danger">
                  {errors.phone_number &&
                    touched.phone_number &&
                    errors.phone_number}
                </span>
              </Form.Group>
              <Form.Group label="Email">
                <FormField
                  appendleft={
                    <Button icon={"mail"} color={"secondary"} disabled />
                  }
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  onBlur={async (e) => {
                    await HandleValidateEmail(e.target.value);
                  }}
                  //  appendright={<Button icon={'unlock'} color={'white'} disabled />}
                />
              </Form.Group>
            </Card.Body>

            <Card.Footer className="d-block d-sm-flex justify-content-between">
              <Button.List className="text-right text-sm-left mb-3 mb-sm-0">
                {user?.email && user.registration_id && (
                  <Button
                    icon="mail"
                    className="text-muted"
                    value="Reset Password"
                    color="white"
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      confirmResetPasswordByEmail();
                    }}
                  >
                    RESET PASSWORD
                    {/* Button.Dropdown (keep for later)
                    {user.phone_number && (
                      <Dropdown.Item
                        to="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          confirmResetPasswordByPhone();
                        }}
                      >
                        Reset by SMS
                      </Dropdown.Item>
                    )}
                    */}
                  </Button>
                )}
                {values.email && !user.registration_id && (
                  <Button
                    icon="mail"
                    size="sm"
                    color="white"
                    className="text-muted"
                    onClick={(e) => {
                      e.preventDefault();
                      confirmSendActivationEmail();
                    }}
                  >
                    SEND ACTIVATION
                  </Button>
                )}
              </Button.List>
              <Button.List align="right">
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  pill
                  color="gray-dark"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  UPDATE
                </Button>
              </Button.List>
            </Card.Footer>
          </>
        );
      }}
    </Formik>
  );
};

export default observer(UserAccountForm);
