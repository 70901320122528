import { useQuery } from '@apollo/client'
import { useRootStore } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Select from 'react-select'
import {
  Avatar,
  Button,
  Card,
  Dimmer,
  Form,
  Grid,
  Page,
  Text
} from 'tabler-react'
import { GET_FIGHTERS_SEARCH } from '../../graphql/GET_FIGHTERS_SEARCH'
import { GET_TEAM_SPORTS } from '../../graphql/GET_TEAM_SPORTS'
import { GET_TEAM_SPORTS as GET_TEAM_SPORTS_TYPE } from '../../graphql/types/GET_TEAM_SPORTS'
import { countries, domainActivities } from '../constants'
import { Loading } from '../Loading'

const defaultFilter = {
  search: '',
  stance: null,
  reach: { min: null, max: null },
  activity: null,
  country: null,
  weight: { min: null, max: null },
  page: 0,
  limit: 50
}

const FighterListInner = () => {
  const [filter, setFilter] = useState(defaultFilter)
  const [fighters, setFighters] = useState([])
  const [totals, setTotals] = useState([])
  const [total, setTotal] = useState(0)
  const { currentCoachTeam } = useRootStore()
  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_FIGHTERS_SEARCH,
    {
      variables: {
        filter: defaultFilter
      }
    }
  )

  const { data: teamSportsData, loading: teamSportsLoading } =
    useQuery<GET_TEAM_SPORTS_TYPE>(GET_TEAM_SPORTS, {
      variables: {
        team_id: currentCoachTeam.id.toString() ?? '1'
      }
    })

  const sportTypeList = useMemo(() => {
    return (
      teamSportsData?.teamSports.filter((teamSport) =>
        domainActivities.fairfights.includes(teamSport.sport_type_id)
      ) ?? []
    )
  }, [teamSportsData?.teamSports, teamSportsLoading])

  const onFetchMore = () => {
    handleFilterChange('page', filter.page + 1)
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache
        }

        return {
          ...cache,
          fighterSearch: {
            fighters: [
              ...cache.fighterSearch.fighters,
              ...fetchMoreResult.fighterSearch.fighters
            ],
            totals: fetchMoreResult.fighterSearch.totals,
            total: fetchMoreResult.fighterSearch.total
          }
        }
      },
      variables: {
        filter: { ...filter, page: filter.page + 1 }
      }
    })
  }

  const handleRefetch = async () => {
    await refetch({
      filter
    })
  }

  const handleResetFilters = async () => {
    setFilter(defaultFilter)
    await refetch({
      filter: defaultFilter
    })
  }

  const handleFilterChange = (name, value) => {
    if (name === 'reach' || name === 'weight') {
      setFilter({
        ...filter,
        [name]: { min: Number(value.min), max: Number(value.max) }
      })
    } else {
      setFilter({ ...filter, [name]: value })
    }
  }

  useEffect(() => {
    if (error) {
      console.error(error)
      setFighters([])
      setTotals([])
    }
    if (data && !loading) {
      setFighters(data.fighterSearch.fighters)
      setTotals(data.fighterSearch.totals)
      setTotal(data.fighterSearch.total)
    }
  }, [error, data, loading])

  if (loading) return <Loading />

  return (
    <>
      <Page.Header>
        <div className="container">
          <h1 className="page-title">Fighter Search</h1>
          <Text.Small className={'text-muted-dark'}>
            {fighters.length} results
          </Text.Small>
        </div>
      </Page.Header>
      <Page.Content>
        <Grid.Row>
          <Grid.Col xs="12" sm="12" md="12" lg="3">
            <Form.Group>
              <Form.MaskedInput
                autoFocus
                name="search"
                placeholder="Search fighters..."
              />
            </Form.Group>
            <h5>Stance</h5>
            <Form.Group>
              <Text className="float-right text-muted pt-1">
                {totals.find(
                  (t) => t.stance && t.stance.toLowerCase() === 'orthodox'
                )
                  ? totals.find(
                      (t) => t.stance && t.stance.toLowerCase() === 'orthodox'
                    ).total
                  : 0}
              </Text>
              <Form.Checkbox
                name="stance"
                value={'orthodox'}
                checked={filter.stance === 'orthodox'}
                label="Orthodox"
                onChange={(e) => handleFilterChange('stance', 'orthodox')}
              />
              <Text className="float-right text-muted pt-1">
                {totals.find(
                  (t) => t.stance && t.stance.toLowerCase() === 'southpaw'
                )
                  ? totals.find(
                      (t) => t.stance && t.stance.toLowerCase() === 'southpaw'
                    ).total
                  : 0}
              </Text>
              <Form.Checkbox
                name="stance"
                value={'southpaw'}
                checked={filter.stance === 'southpaw'}
                label="Southpaw"
                onChange={(e) => handleFilterChange('stance', 'southpaw')}
              />
            </Form.Group>
            <h5>Activities</h5>
            <Form.Group>
              {sportTypeList.map((sportType) => (
                <Form.Checkbox
                  key={sportType.sport_type_id}
                  name="activity"
                  value={sportType.sport_type_id}
                  checked={filter.activity === sportType.sport_type_id}
                  label={sportType.name}
                  onChange={(e) =>
                    handleFilterChange('activity', sportType.sport_type_id)
                  }
                />
              ))}
            </Form.Group>
            <h5>Weight</h5>
            <Form.Group>
              <Form.InputGroup>
                <Form.InputGroupAppend>
                  <Button color="secondary" disabled>
                    LBS
                  </Button>
                </Form.InputGroupAppend>
                <Form.Input
                  name="weight"
                  placeholder="Min"
                  type="number"
                  onChange={(e) =>
                    handleFilterChange('weight', {
                      min: e.target.value,
                      max: filter.weight.max
                    })
                  }
                />{' '}
                -
                <Form.InputGroupAppend>
                  <Button color="secondary" disabled>
                    LBS
                  </Button>
                </Form.InputGroupAppend>
                <Form.Input
                  name="weight"
                  placeholder="Max"
                  type="number"
                  onChange={(e) =>
                    handleFilterChange('weight', {
                      min: filter.weight.min,
                      max: e.target.value
                    })
                  }
                />
              </Form.InputGroup>
            </Form.Group>
            <h5>Country</h5>
            <Form.Group>
              <Select
                backspaceRemovesValue={true}
                escapeClearsValue={true}
                getOptionLabel={(option: { name: string }) => option.name}
                getOptionValue={(option: { name: string }) => option.name}
                isClearable={true}
                name="country"
                options={countries}
                onChange={(params: { name: string }) => {
                  if (params)
                    handleFilterChange('country', params?.name ?? null)
                  else handleFilterChange('country', null)
                }}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                value={
                  filter.country &&
                  countries[
                    Object.keys(countries).find(
                      (key) => countries[key].name === filter.country
                    )
                  ]
                }
              />
            </Form.Group>
            {/*<h5>Affiliation</h5>*/}
            <Form.Group>
              <Button block color="primary" onClick={handleRefetch}>
                Confirm Changes
              </Button>
            </Form.Group>
            <Form.Group>
              <Button
                block
                color="secondary"
                onClick={handleResetFilters}
                disabled={loading}
              >
                Reset Filters
              </Button>
            </Form.Group>
          </Grid.Col>
          <Grid.Col lg="9">
            <Dimmer active={loading} loader={loading}>
              <InfiniteScroll
                className="overflow-visible pb-3"
                dataLength={fighters.length}
                hasMore={fighters.length < (total || 0)}
                loader={<Dimmer active={true} loader={true} />}
                next={onFetchMore}
                scrollThreshold="210px"
              >
                <Grid.Col width={12}>
                  <Grid.Row>
                    {fighters.map((fighter) => (
                      <Grid.Col width={4} key={fighter.id}>
                        <Card className="card-profile">
                          <Card.Header
                            backgroundURL={
                              (fighter?.profile_picture &&
                                `/api/s3/uploads/${fighter?.profile_avatar}`) ||
                              '//via.placeholder.com/374x144.png?text=+'
                            }
                          ></Card.Header>
                          <Card.Body>
                            <Grid.Row>
                              <Grid.Col width={12}>
                                <Text className={'m-2'}>
                                  <p><strong>{fighter.first_name} {fighter.last_name}</strong></p>
                                  <p>{fighter.weight && 'Weight: ' + fighter.weight}</p>
                                  <p>{fighter.country && 'Country: ' + fighter.country}</p>
                                </Text>
                              </Grid.Col>
                            </Grid.Row>
                          </Card.Body>
                        </Card>
                      </Grid.Col>
                    ))}
                  </Grid.Row>
                </Grid.Col>
              </InfiniteScroll>
            </Dimmer>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </>
  )
}

export default FighterListInner
