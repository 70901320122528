import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid } from "tabler-react";
import { EDIT_GROUP_MEMBER } from "../../../graphql/EDIT_GROUP_MEMBER";
import { GET_CAMP_ROLES } from "../../../graphql/GET_CAMP_ROLES";
import { Types } from "../../../types/graphql";
import Modal from "../../Modal";

interface EditRegistrationGroupMemberModalProps {
  isOpen: boolean;
  toggle: () => void;
  member: Types.GroupMember;
  campId: number;
  groupId: number;
}
const EditRegistrationGroupMemberModal = ({
  isOpen,
  member,
  toggle,
  campId,
  groupId,
}: EditRegistrationGroupMemberModalProps) => {
  const [campRoles, setCampRoles] = React.useState([]);
  const { data: campRolesData } = useQuery(GET_CAMP_ROLES, {
    variables: { campId },
  });

  const [updateGroupMember] = useMutation(EDIT_GROUP_MEMBER, {
    onCompleted: () => {
      toast.success("Group member updated");
      toggle();
    },
    refetchQueries: ["GET_GROUPS"],
  });

  useEffect(() => {
    if (campRolesData?.campRoles) {
      setCampRoles(
        campRolesData.campRoles
          .filter((role) => role.isExists)
          .map((role) => ({
            label: role.name,
            value: role.roleId,
          })),
      );
    }
  }, [campRolesData]);

  const renderForm = ({
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
  }) => {
    return (
<>
          <Grid.Row>
            <Grid.Col>
              <Form.Group>
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={campRoles}
                  name="role_id"
                  onChange={(option) => {
                    setFieldValue("role_id", option.value);
                  }}
                  value={campRoles.find((i) => i.value === values.role_id)}
                />
                <span className="field-error text-danger">
                  {errors.role_id && touched.role_id && errors.role_id}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        <Grid.Row>
          <Button
            pill
            size={"small"}
            color={"gray-dark"}
            className={"float-right"}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
          <Button
            pill
            size={"small"}
            color={"white"}
            className={"float-right"}
            onClick={toggle}
          >
            CANCEL
          </Button>
        </Grid.Row>
        </>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title={`${member.first_name} ${member.last_name}`}
      content={
        <Formik
          initialValues={{
            group_id: groupId,
            student_id: member.id,
            role_id: member.roleId,
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await updateGroupMember({
              variables: {
                updateGroupAssociatedUsersInput: {
                  group_id: values.group_id,
                  student_id: values.student_id,
                  role_id: values.role_id,
                },
              },
            });

            setSubmitting(false);
            resetForm();
          }}
        >
          {(formData) => renderForm(formData)}
        </Formik>
      }
    />
  );
};

export default EditRegistrationGroupMemberModal;
