import { useQuery } from '@apollo/client'
import GroupFormModal from 'components/Calendar/Modal/GroupFormModal'
import { GET_GROUPS } from 'graphql/GET_GROUPS'
import React, { useMemo, useState } from 'react'
import { Button, Card, Dimmer, Grid } from 'tabler-react'
import useRouter from 'use-react-router'
import { GET_PAYMENT_FEE_BY_TEAM_ID } from '../../graphql/GET_PAYMENT_FEE_BY_TEAM_ID'
import { GET_STRIPE_PUBLIC_KEY } from '../../graphql/GET_STRIPE_PUBLIC_KEY'
import { GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE } from '../../graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID'
import { Types } from '../../types/graphql'
import { Loading } from '../Loading'
import CompetitionGroupsListItem from './CompetitionGroupsListItem'

interface CompetitionGroupsListProps {
  campId: string
  teamId: string
  campName: string
  students: Types.CampStudents[]
}

const CompetitionGroupsList = ({
  campId,
  teamId,
  campName,
  students
}: CompetitionGroupsListProps) => {
  const { history } = useRouter()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data, loading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        camp_id: Number(campId),
        team_id: teamId,
        status: 'Active'
      }
    }
  })

  const groupList = useMemo(() => {
    if (data) {
      return data.groupList
    }

    return []
  }, [data?.groupList])
  const { loading: loading1, data: stripeData } = useQuery(
    GET_STRIPE_PUBLIC_KEY
  )

  const stripeKey = useMemo(
    () => stripeData?.getStripePublicKey,

    [stripeData?.getStripePublicKey]
  )

  const { loading: loading2, data: paymentData } =
    useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(GET_PAYMENT_FEE_BY_TEAM_ID, {
      variables: {
        teamId: Number(teamId)
      }
    })

  const paymentFee =
    paymentData?.getPaymentFeeByTeamId.payment_fee_customer / 100

  if (loading1 || loading2) {
    return <Loading />
  }

  return (
    <Card.Body>
      <Dimmer active={loading} loading={loading}>
        {data && (
          <>
            <Grid.Row>
              <Grid.Col md={3} lg={2}>
                <Button
                  block
                  icon="plus"
                  color="secondary"
                  className="mb-5"
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  GROUP
                </Button>
                {students.length > 0 && (
                  <div>
                    <h4>Participants</h4>
                    <div className="overflow-scrolly pr-1">
                      {students.map((student) => {
                        // Check if student is in multiple groups
                        // If so, display indicator icon
                        const studentGroups = groupList.filter((group) => {
                          return group.members.some(
                            (member) => Number(member.id) === student.student_id
                          )
                        })
                        return (
                          <div key={student.id}>
                            <Button
                              block
                              outline={studentGroups.length === 0}
                              icon={
                                studentGroups.length === 1
                                  ? 'user'
                                  : studentGroups.length > 1
                                  ? 'users'
                                  : 'user-x'
                              }
                              color={
                                studentGroups.length > 1 ? 'info' : 'primary'
                              }
                              size="sm"
                              className="mb-2 text-left"
                              onClick={() => {
                                history.push(
                                  `/events/registrations/${student.user_registrations_id}/${student.student_id}`
                                )
                              }}
                            >
                              {`${student.first_name} ${student.last_name}`}
                            </Button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </Grid.Col>
              <Grid.Col md={10}>
                <Grid.Row>
                  {groupList.map((group) => (
                    <Grid.Col md={4} key={group.id}>
                      <div>
                        <CompetitionGroupsListItem
                          group={group}
                          groupList={groupList}
                          stripeKey={stripeKey}
                          paymentFee={paymentFee}
                        />
                      </div>
                    </Grid.Col>
                  ))}
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            <GroupFormModal
              isOpen={isModalOpen}
              toggle={setIsModalOpen}
              campId={campId}
              teamId={teamId}
            />
          </>
        )}
      </Dimmer>
    </Card.Body>
  )
}

export default CompetitionGroupsList
