import FighterDetails from 'components/User/FighterDetails'
import React from 'react'
import Wrapper from '../layout'

const FighterDetailsPage = (props) => {
  return (
    <Wrapper {...props}>
      <div className="container">
        <FighterDetails />
      </div>
    </Wrapper>
  )
}

export default FighterDetailsPage
