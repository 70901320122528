import { useQuery } from '@apollo/client'
import UserRegistration from 'components/User/UserRegistration'
import { GET_USER_REGISTRATIONS_BY_IDS } from 'graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { GET_USER_REGISTRATIONS_BY_IDS as GET_USER_REGISTRATIONS_BY_IDS_TYPE } from 'graphql/types/GET_USER_REGISTRATIONS_BY_IDS'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import {
  useGetUserIsAdmin,
  useGetUserIsStudent
} from 'modules/common/hooks/useGetCurrentUserType'
import Wrapper from 'pages/layout'
import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { Container, Dimmer } from 'tabler-react'
import { GET_CAMP_COHOSTS } from '../../graphql/GET_CAMP_COHOSTS'
import useGetEventOrganizers from '../../modules/user/roles/hooks/useGetEventOrganizers'

import PageNoAccess from '../../pages/PageNoAccess'

const AdminEventsRegistrationsPage = (props) => {
  const isStudent = useGetUserIsStudent()
  const isAdmin = useGetUserIsAdmin()

  const { currentUser, currentCoachTeam } = useRootStore()
  const { userRegId, studentId } = useParams<{
    userRegId: string
    studentId: string
  }>()

  const { data: registrationData, loading: registrationDataLoading } =
    useQuery<GET_USER_REGISTRATIONS_BY_IDS_TYPE>(
      GET_USER_REGISTRATIONS_BY_IDS,
      {
        variables: {
          userRegistrationsId: Number(userRegId)
        }
      }
    )
  const campId = Number(registrationData?.getUserRegistrationsByIds.camp_id)
  const { eventOrganizersDataLoading, isOrganizer } = useGetEventOrganizers({
    eventId: campId
  })

  const { loading: loadingTeams, data: dataTeams } = useQuery(
    GET_CAMP_COHOSTS,
    {
      variables: {
        campId: campId
      },
      fetchPolicy: 'network-only'
    }
  )

  const coHostingTeams = useMemo(() => {
    if (loadingTeams || !dataTeams?.getCampCoHosts) return []
    return dataTeams?.getCampCoHosts.filter((team) => team.is_active)
  }, [dataTeams, loadingTeams])

  const isCurrentTeamCoHost = coHostingTeams.some(
    (team) => team.id === currentCoachTeam?.id
  )

  const hasAccess = useCallback(() => {
    return (
      isOrganizer ||
      isCurrentTeamCoHost ||
      (isStudent && currentUser?.id === Number(studentId)) ||
      (currentCoachTeam &&
        registrationData?.getUserRegistrationsByIds.team_id ===
          currentCoachTeam?.id) ||
      isAdmin
    )
  }, [
    currentCoachTeam,
    isOrganizer,
    currentUser,
    isStudent,
    registrationData,
    studentId,
    isAdmin,
    isCurrentTeamCoHost
  ])

  if (!eventOrganizersDataLoading && !registrationDataLoading && !hasAccess()) {
    return <PageNoAccess />
  }

  return (
    <Wrapper {...props}>
      <Container>
        <Dimmer
          active={registrationDataLoading && eventOrganizersDataLoading}
          loader={registrationDataLoading && eventOrganizersDataLoading}
        >
          <UserRegistration />
        </Dimmer>
      </Container>
    </Wrapper>
  )
}

export default observer(AdminEventsRegistrationsPage)
