import { useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import { Tag } from 'tabler-react'
import { GET_USER_ROLES } from '../../../graphql/GET_USER_ROLES'
import { GET_USER_ROLES as GET_USER_ROLES_TYPE } from '../../../graphql/types/GET_USER_ROLES'
import { formatMoney } from '../../../utils/numberFormat'
import useGetCurrentUserType from '../../common/hooks/useGetCurrentUserType'
import UserRoleModal from '../../user/roles/components/modals/UserRoleModal'

type CoachRolesListProps = {
  coach: any
}
const CoachRolesList = ({ coach }: CoachRolesListProps) => {
  const { isDzm, isWtm, isCoachAdmin, isCoachSuperAdmin } =
    useGetCurrentUserType()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const { data } = useQuery<GET_USER_ROLES_TYPE>(GET_USER_ROLES, {
    variables: {
      params: {
        coachId: Number(coach?.id)
      }
    }
  })

  const existingRolesRow = useMemo(
    () =>
      data?.getUserRolesByTeamId.map(({ id, name, compensation }) => ({
        id,
        name,
        compensation
      })) ?? [],
    [data?.getUserRolesByTeamId]
  )
  return (
    <Tag.List className="float-right">
      {(isCoachAdmin || isCoachSuperAdmin) && (
        <Tag className="card-body-click" onClick={toggleModal}>
          +
        </Tag>
      )}
      {existingRolesRow.map((role) => (
        <Tag key={role.id}>
          {role.name}{' '}
          <span className="ml-2 text-success">
            {role.compensation &&
              (isDzm || isWtm) &&
              `${formatMoney(Number(role.compensation))}`}
          </span>
        </Tag>
      ))}

      <UserRoleModal
        action="addCoachRole"
        isModalOpen={isModalOpen}
        name={`${coach?.first_name} ${coach?.last_name}`}
        coachId={coach?.id}
        toggleModal={toggleModal}
      />
    </Tag.List>
  )
}

export default CoachRolesList
