import React from 'react'
import FighterListInner from '../../components/Fighters/FighterListInner'
import Wrapper from '../layout'

const FighterList = (props) => {
  return (
    <Wrapper {...props} title="Fighters">
      <FighterListInner />
    </Wrapper>
  )
}

export default FighterList
