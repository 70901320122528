import { useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { Button } from 'tabler-react'
import { ADD_ROLE_TO_STAFF } from '../../../../graphql/ADD_ROLE_TO_STAFF'
import { useRootStore } from '../../../../hooks'
import { useGetUserIsFighter } from '../../../common/hooks/useGetCurrentUserType'

const FighterProfileButton = () => {
  const { currentUser } = useRootStore()
  const isFighter = useGetUserIsFighter()
  const [addRoleToStaff, { loading }] = useMutation(ADD_ROLE_TO_STAFF, {
    onError: (error) => toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => toast.success('Role added.')
  })

  const handleAddRoleToStaff = async () => {
    await addRoleToStaff({
      variables: {
        teamStaff: {
          roleId: 35, //fighter
          studentId: currentUser.id
        }
      }
    })
    window.location.href = '/fighter-details'
  }
  const handleRedirectToFighterProfile = () => {
    window.location.href = '/fighter-details'
  }
  return (
    <Button
      color="white"
      size="sm"
      className="text-muted float-right"
      onClick={() => {
        if (isFighter) {
          handleRedirectToFighterProfile()
        } else {
          handleAddRoleToStaff()
        }
      }}
      disabled={loading}
    >
      {!isFighter && 'Create'} Fighter Profile
    </Button>
  )
}

export default FighterProfileButton
