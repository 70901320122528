import useGetCampById from 'modules/camp/hooks/useGetCampById'
import useGetRegistrationStatus from 'modules/team-registration-status/hooks/useGetRegistrationStatus'
import {
  PaymentStatusType,
  RegistrationStatusType,
  USPAStatusType
} from 'modules/user/registrations/constants/api'
import { STATUS_ICON_COLOR_MAPPING } from 'modules/user/registrations/constants/registrationStatusMapping'
import React from 'react'
import { useParams } from 'react-router'
import { Alert, Button, Card, Form, Table, Tag } from 'tabler-react'
import { Types } from '../../../../../types/graphql'

type Props = {
  checkedState: Record<string, boolean>
  isAllChecked: boolean
  paymentStatus: PaymentStatusType
  registeredStudentRows: Types.RegisteredStudents[]
  registrationStatus: RegistrationStatusType
  uspaStatus: USPAStatusType
  handleCheckAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleCheckChange: (id: string) => void
  handleSetPaymentStatus: (status: PaymentStatusType) => void
  handleSetRegistrationStatus: (status: RegistrationStatusType) => void
  handleSetUspaStatus: (status: USPAStatusType) => void
  toggleModal: () => void
}

const RecipientListModal = ({
  checkedState,
  isAllChecked,
  paymentStatus,
  registeredStudentRows,
  registrationStatus,
  uspaStatus,
  handleCheckAllChange,
  handleCheckChange,
  handleSetPaymentStatus,
  handleSetRegistrationStatus,
  handleSetUspaStatus,
  toggleModal
}: Props) => {
  const { campId } = useParams<{ campId: string }>()

  const { data } = useGetCampById(Number(campId))

  const { teamStatusOptions } = useGetRegistrationStatus(
    data?.getCampById.team_id ? Number(data?.getCampById.team_id) : 0
  )

  return (
    <>
      <Card.Header className="px-0">
        <Card.Options className="gap-3">
          <Form.Group label="Status">
            <Form.Select
              onChange={(e) => {
                handleSetRegistrationStatus(e.target.value)
              }}
              value={registrationStatus}
            >
              <option value="">Select</option>

              {teamStatusOptions?.map((status) => {
                return (
                  <option key={status.id} value={status.name}>
                    {status.name}
                  </option>
                )
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group label="Payment">
            <Form.Select
              onChange={(e) => {
                handleSetPaymentStatus(e.target.value)
              }}
              value={paymentStatus}
            >
              <option value="">Select</option>
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </Form.Select>
          </Form.Group>
          <Form.Group label="Credentials">
            <Form.Select
              onChange={(e) => {
                handleSetUspaStatus(e.target.value)
              }}
              value={uspaStatus}
            >
              <option value="">Select</option>
              <option value="USPA Unknown">Unknown</option>
              <option value="USPA Active">Active</option>
              <option value="USPA Active Expiring">Expiring</option>
            </Form.Select>
          </Form.Group>
        </Card.Options>
      </Card.Header>
      <Card.Body className="px-0">
        <Table>
          {registeredStudentRows?.length ? (
            <>
              <Form.Checkbox
                checked={isAllChecked}
                onChange={handleCheckAllChange}
                label="Select All"
                className="text-muted"
              />
              {registeredStudentRows.map((student) => {
                const remainingBalance = () => {
                  const balance = student.paymentDetail.outstanding / 100
                  return balance > 0 ? balance : 0
                }

                let statusIcon = 'bg-danger'
                if (student.uspa.uspaStatus === 'USPA Active') {
                  statusIcon = 'bg-success'
                }

                if (student.uspa.isLicenseExpiring) {
                  statusIcon = 'bg-warning'
                }

                return (
                  <Table.Row key={student.id}>
                    <Table.Col className="pl-0 border-0 py-1">
                      <Form.Checkbox
                        label={`${student.first_name} ${student.last_name}`}
                        className="mb-0"
                        checked={checkedState[student.student_id] ?? false}
                        onChange={() =>
                          handleCheckChange(student.student_id.toString())
                        }
                      />
                    </Table.Col>
                    <Table.Col className="text-primary border-0 py-1">
                      <a href={`mailto:${student.email}`}>{student.email}</a>
                    </Table.Col>
                    <Table.Col className="border-0 py-1">
                      <Tag
                        color={
                          STATUS_ICON_COLOR_MAPPING.get(student.status)
                            ?.color ?? 'danger'
                        }
                      >
                        {student.status}
                      </Tag>
                    </Table.Col>
                    <Table.Col className="border-0 py-1">
                      <Tag color="success" className="mr-2">
                        {remainingBalance() > 0 ? '' : 'Paid'}
                      </Tag>
                    </Table.Col>
                    <Table.Col className="border-0 py-1">
                      <Tag>
                        {student.uspa.uspaStatus === 'USPA Unknown' ? (
                          <span>USPA Unknown</span>
                        ) : (
                          <div>
                            <span className={`status-icon ${statusIcon}`} />
                            {student.uspa.uspaStatus}
                          </div>
                        )}
                      </Tag>
                    </Table.Col>
                  </Table.Row>
                )
              })}
            </>
          ) : (
            <Alert type="info text-center">
              <strong>No Data</strong>
            </Alert>
          )}
        </Table>
      </Card.Body>
      <Card.Footer className="text-right px-0 pb-0">
        <Button color="gray-dark" onClick={toggleModal}>
          DONE
        </Button>
      </Card.Footer>
    </>
  )
}

export default RecipientListModal
