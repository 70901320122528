import PaymentHistory from 'components/User/PaymentHistory'
import React from 'react'
import { Card, Grid } from 'tabler-react'
import { Types } from '../../../../types/graphql'

type RegistrationPaymentsProps = {
  userRegistrationDetails: Types.UserRegistrationsById
  outstanding: number
  totalPaid: number
}

const RegistrationPayments: React.FC<RegistrationPaymentsProps> = ({
  userRegistrationDetails,
  outstanding,
  totalPaid
}: RegistrationPaymentsProps) => {
  return (
    <Card>
      <Card.Body>
        <Grid.Row className="">
          <Grid.Col>
            <div className="h5">Payments</div>
            <hr className="border border-primary my-1" />
            <PaymentHistory
              studentId={Number(userRegistrationDetails.student_id)}
              userRegId={userRegistrationDetails.user_registrations_id}
              remainingBalance={outstanding}
              totalPaid={totalPaid}
              currency={userRegistrationDetails.registration_currency}
            />
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  )
}

export default RegistrationPayments
