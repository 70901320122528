import { useMutation, useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import { Alert, Form, Grid, Text } from 'tabler-react'
import { ADD_USER_REGISTRATION_OPTIONS } from '../../../graphql/ADD_USER_REGISTRATION_OPTIONS'
import { DELETE_USER_REGISTRATION_OPTIONS } from '../../../graphql/DELETE_USER_REGISTRATION_OPTIONS'
import { GET_CAMP_REGISTRATION_OPTIONS } from '../../../graphql/GET_CAMP_REGISTRATION_OPTIONS'
import { GET_USER_REGISTRATIONS_BY_IDS } from '../../../graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { formatMoney } from '../../../utils/numberFormat'

interface IUserRegistrationFormProp {
  campId: number
  teamId: number
  userRegistrationOptions: any
  userRegistrationId: number
  defaultJumpCount: number
}

const UpdateUserRegistrationOptions = ({
  campId,
  teamId,
  userRegistrationOptions,
  userRegistrationId,
  defaultJumpCount
}: IUserRegistrationFormProp) => {
  const { data } = useQuery(GET_CAMP_REGISTRATION_OPTIONS, {
    variables: {
      campId: campId,
      teamId: teamId
    }
  })

  const [addUserRegistrationOptions, { loading: loading1 }] = useMutation(
    ADD_USER_REGISTRATION_OPTIONS
  )
  const [removeUserRegistrationOptions, { loading: loading2 }] = useMutation(
    DELETE_USER_REGISTRATION_OPTIONS,
    {
      refetchQueries: [
        {
          query: GET_USER_REGISTRATIONS_BY_IDS,
          variables: {
            userRegistrationsId: userRegistrationId
          }
        }
      ]
    }
  )

  const options = useMemo(() => {
    const campOptions = data?.getAvailableCampRegistrationOptions.filter(
      (option) => option.is_exist
    )
    if (campOptions?.length > 0) {
      const newOptions = []

      campOptions.map((option) => {
        const cloneOption = { ...option }

        const userOptionValue = userRegistrationOptions.find(
          (userOptions, index) =>
            userOptions.registration_option_id == cloneOption.id
        )
        cloneOption.is_exist = !!userOptionValue
        if (cloneOption.is_exist)
          cloneOption.userRegistrationOptionId = userOptionValue.id
        newOptions.push(cloneOption)
      })
      return newOptions
    }
    return []
  }, [data, userRegistrationOptions])

  const optionToggle = async (optionId, action) => {
    if (action === 'add') {
      await addUserRegistrationOptions({
        variables: {
          addUserRegistrationOptionInput: {
            user_registration_id: userRegistrationId,
            registration_option_ids: [Number(optionId)],
            quantity: defaultJumpCount
          }
        },
        refetchQueries: [
          {
            query: GET_USER_REGISTRATIONS_BY_IDS,
            variables: {
              userRegistrationsId: userRegistrationId
            }
          }
        ]
      })
    } else {
      await removeUserRegistrationOptions({
        variables: {
          removeUserRegistrationOptionInput: {
            user_registration_id: userRegistrationId,
            registration_option_ids: [Number(optionId)]
          }
        }
      })
    }
  }

  return (
    <>
      {options.length > 0 ?
        options.map((option) => {
          return (
            <Grid.Row key={option.id}>
              <Grid.Col>
                <Form.Switch
                  name="status"
                  label={
                    <>
                      <p className="mb-0">
                        <strong>{option.name}</strong>
                      </p>
                      <p>
                        {formatMoney(Number(option.option_value))}{' '}
                        {option.fee_type_name}
                      </p>
                    </>
                  }
                  checked={option.is_exist}
                  disabled={loading1 || loading2}
                  onChange={(e) => {
                    if (e.target.checked) optionToggle(option.id, 'add')
                    else optionToggle(option.id, 'remove')
                  }}
                />
              </Grid.Col>
            </Grid.Row>
          )
        }) : (
          <>
            <Alert type="info" className="text-center">
              <strong>No options found</strong>
            </Alert>
            <Text.Small className="text-muted">
              Manage event options on the Event Settings page.
            </Text.Small>
          </>
        )}
    </>
  )
}

export default UpdateUserRegistrationOptions
