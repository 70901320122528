import { clone } from 'mobx-state-tree'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Grid } from 'tabler-react'
import { CoachEvents } from '../../../components/Coaches/CoachDetails'
import EditProfileForm from '../../../components/User/Forms/EditProfileForm'
import UserEmergencyContact from '../../../components/User/UserEmergencyContact'
import UserProfileForm from '../../../components/User/Forms/UserProfileForm'
import UserCard from '../../../components/User/Profile/UserCard'
import { UserStoreContext } from '../../../contexts/userStoreContext'
import { useRootStore } from '../../../hooks'
import useGetCurrentUserType from '../../common/hooks/useGetCurrentUserType'
import useGetStudentById from '../../student/hooks/useGetStudentById'

const FighterSettings = () => {
  const userStore = useContext(UserStoreContext)
  const { currentUser, domainData } = useRootStore()
  const { isAdmin, isStudent, isCoachSuperAdmin } = useGetCurrentUserType()

  const { data, loading } = useGetStudentById(String(currentUser?.id))

  const userClone = clone(currentUser)
  const [display, setDisplay] = useState('settings')

  useEffect(() => {
    if (data?.getStudentById) {
      userStore.loadUser(data.getStudentById)
    }
  }, [data?.getStudentById])
  return (
    <Grid.Row>
      <Grid.Col lg={4} sm={12} xs={12}>
        <UserCard
          firstName={currentUser?.first_name}
          lastName={currentUser?.last_name}
          profileAvatar={currentUser?.profile_avatar}
          profilePicture={currentUser?.profile_picture}
        />
      </Grid.Col>
      <Grid.Col lg={8} sm={12} xs={12}>
        {isStudent && (
          <Card>
            <UserProfileForm userProfile={data?.getStudentById} />
          </Card>
        )}
        {isCoachSuperAdmin && (
          <Grid.Row className="mb-2">
            <Grid.Col>
              <Button.List className="float-right">
                <Button
                  color={`${display === 'activity' ? 'primary' : 'white'}`}
                  onClick={() => setDisplay('activity')}
                >
                  Activity
                </Button>
                <Button
                  color={`${display === 'settings' ? 'primary' : 'white'}`}
                  onClick={() => setDisplay('settings')}
                >
                  Settings
                </Button>
              </Button.List>
            </Grid.Col>
          </Grid.Row>
        )}
        {display === 'activity' && (
          <Grid.Row>
            <Grid.Col>
              <CoachEvents coachId={currentUser.id} />
            </Grid.Col>
          </Grid.Row>
        )}
        {!isStudent && display === 'settings' && (
          <EditProfileForm user={userClone} />
        )}{' '}
      </Grid.Col>
    </Grid.Row>
  )
}

export default FighterSettings
