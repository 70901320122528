import gql from 'graphql-tag';

export const GET_GROUPS_BY_FILTER_QUERY = gql`
  query GET_GROUPS_BY_FILTER_QUERY($filter: String!, $campId: ID) {
    groupsSearch(filter: $filter, campId: $campId) {
      id
      name
      camp_id
      discipline_id
      discipline
      team_id
      team_name
      camp_name
      seq
      sport_type_id
      group_code
      user_registration_id
      image
      status
      color
      members {
        first_name
        last_name
        id
        email
        phone_number
        phone_country_code
        profile_avatar
        roleName
        roleId
        registration_id
      }
    }
  }
`;
