import React from 'react';
import { observer } from 'mobx-react';

import UserDetails from '../../components/User/UserDetails';
import Wrapper from '../layout';

const UserDetailsPage = (props) => {
  return (
    <Wrapper {...props}>
      <div className="container">
        <UserDetails />
      </div>
    </Wrapper>
  );
};

export default observer(UserDetailsPage);
