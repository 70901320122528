import { STATUS_ICON_COLOR_MAPPING } from "modules/user/registrations/constants/registrationStatusMapping";
import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, Dimmer, Icon, Text } from "tabler-react";
import useRouter from "use-react-router";
import { Types } from "../../types/graphql";
import useGetCurrentUserType from "../../modules/common/hooks/useGetCurrentUserType";

interface IStudentCamps {
  camps: Types.StudentCamps[];
  loading: boolean;
  studentId: number;
}

const StudentCamps = ({ loading, camps, studentId }: IStudentCamps) => {
  const { history } = useRouter();
  const { isStudent } = useGetCurrentUserType();

  return (
    <Dimmer active={loading} loader={loading}>
      <Card statusColor="gray-dark">
        <Card.Header>
          <Card.Title>
            <i className="fe fe-calendar text-primary mr-2" />
            Event Registrations
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {camps?.length > 0 ? (
            <>
              <Text>
                {!loading &&
                  [...camps].map((camp: Types.StudentCamps) => (
                    <div key={camp.camp_id}>
                      <span className="ml-3 text-muted float-right">
                        {!isStudent && camp.rating > 0 && (
                          <Button
                            icon="star"
                            color="white"
                            size="sm"
                            className="text-warning"
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(
                                `/events/registrations/${camp.user_registrations_id}/${studentId}`,
                              );
                            }}
                          >
                            {camp.rating}
                          </Button>
                        )}
                        {camp.user_registrations_id && (
                          <Button
                            size="sm"
                            color="white"
                            className="text-success"
                          >
                            <Link
                              to={`/events/registrations/${camp.user_registrations_id}/${studentId}`}
                            >
                              <Icon
                                name={
                                  STATUS_ICON_COLOR_MAPPING.get(camp?.status)
                                    ?.icon ?? "x-circle"
                                }
                                className={`ml-0
                                        ${
                                          STATUS_ICON_COLOR_MAPPING.get(
                                            camp?.status,
                                          )?.className ?? "text-danger"
                                        }`}
                              />
                            </Link>
                          </Button>
                        )}
                      </span>
                      <p>
                        <span className="">
                          <Link to={"/user/calendar/" + camp.camp_id}>
                            {camp.camp_name}
                          </Link>
                        </span>
                        <Text.Small className="d-block text-muted">
                        <strong>{moment(camp.start).format("MMM D")}</strong> @ {camp.location.name}
                        </Text.Small>
                        <Text.Small className="d-block text-muted">
                          {camp.team_name}
                        </Text.Small>
                      </p>
                    </div>
                  ))}
              </Text>
            </>
          ) : (
            <>
            <Button.List align="center">
              {isStudent ? (
              <Button
                icon="search"
                color="primary"
                size="sm"
                className=""
                onClick={(): void => history.push("/user/calendar")}
              >
                FIND EVENTS
              </Button>
            ) : (
              <Alert type="info text-center">
                <strong>No Events</strong>
              </Alert>
            )}
            </Button.List>
            </>
          )}
        </Card.Body>
      </Card>
    </Dimmer>
  );
};
export default StudentCamps;
