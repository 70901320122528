import Wrapper from 'pages/layout'

const EventFinancePage = (props) => {
  return (
    <Wrapper {...props} title="Finance Report">
      <div className="container">{/* <EventFinance /> */}</div>
    </Wrapper>
  )
}

export default EventFinancePage
