import React from 'react';
import { Container } from 'tabler-react';

import EventRegistrationList from '../../../../components/Event/EventRegistrationList';
import Wrapper from '../../../layout';

const EventRegistrationListPage = (props) => {
  return (
    <Wrapper {...props}>
      <Container>
        <EventRegistrationList />
      </Container>
    </Wrapper>
  );
};

export default EventRegistrationListPage;
