import { useQuery } from "@apollo/client";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import UserRoleModal from "modules/user/roles/components/modals/UserRoleModal";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Dropdown, Icon, Table, Tag, Text } from "tabler-react";
import useReactRouter from "use-react-router";
import formatSeal from "utils/formatSeal";
import { formatPhoneNumberWithCountryCode } from "utils/phoneFormat";
import { GET_USER_ROLES } from "../../graphql/GET_USER_ROLES";
import { Types } from "../../types/graphql";
import CoachSealEditModal from "../Coaches/Forms/CoachSealEditModal";
import { useRootStore } from 'hooks';

interface IStaffCoachListItemProps {
  coach: Types.TeamCoach;
  teamId: number;
}

const StaffCoachListItem: FC<IStaffCoachListItemProps> = ({
  coach,
  teamId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSealModalOpen, setIsSealModalOpen] = useState(false);
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin, isRigger } =
    useGetCurrentUserType();
  const { history } = useReactRouter();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const { data } = useQuery(GET_USER_ROLES, {
    variables: { params: { coachId: coach.id, teamId: teamId } },
  });
  const { domainData } = useRootStore()

  const adminOption =
    isAdmin || isRigger
      ? [
          {
            value: (
              <span className="text-muted cursor-pointer">
                <i className="fe fe-briefcase mr-2" />
                Jobs
              </span>
            ),
            onClick: () => history.push(`/admin/jobs/${coach.id}`),
          },
          {
            value: (
              <span className="text-muted cursor-pointer">
                <i className="fe fe-award mr-2" />
                Manage Seal
              </span>
            ),
            onClick: () => setIsSealModalOpen(true),
          },
        ]
      : [];
  const options = [
    {
      value: (
        <span className="text-muted cursor-pointer">
          <i className="fe fe-edit mr-2" />
          Edit
        </span>
      ),
    },
    ...adminOption,
    {
      value: (
        <span className="text-muted cursor-pointer">
          <i className="fe fe-lock mr-2" />
          Reset Password
        </span>
      ),
    },
    {
      value: (
        <span className="text-muted cursor-pointer text-danger">
          <i className="fe fe-x-circle mr-2" />
          Revoke access
        </span>
      ),
    },
  ];
  return (
    <>
      <Table.Col>
        <Avatar
          size="md"
          className="float-left mr-5 cursor-pointer"
          status={`${coach.email ? "success" : "secondary"}`}
          imageURL={
            (coach.profile_avatar &&
              `/api/s3/uploads/${coach.profile_avatar}`) ||
            "//www.gravatar.com/avatar?d=mp"
          }
          to="#"
        />
        <Link
          className="text-inherit"
          onClick={(e) => {
            e.preventDefault();
          }}
          to="#"
        >
          {coach.first_name} {coach.last_name}
        </Link>
        <Text.Small className="d-block" color="secondary">
          {domainData.key === 'skycru' ? 'SKY.D: ' : 'ID: '}0{coach.id}{" "}
          <Tag className="ml-2">{formatSeal(coach?.seal?.rigger_seal)}</Tag>
        </Text.Small>
      </Table.Col>
      <Table.Col>
        {coach.email ? (
          <a href={"mailto:" + coach.email}>
            <Icon name="mail" className="mr-2 d-block d-lg-none" />
            <span className="d-none d-lg-block">{coach.email}</span>
          </a>
        ) : (
          <Text className="text-muted">No Login Access</Text>
        )}
      </Table.Col>
      <Table.Col>
        {coach.phone_number && (
          <a href={"tel:" + coach.phone_number}>
            <Icon name="phone" className="mr-2 d-block d-lg-none" />
            <span className="d-none d-lg-block">
              {formatPhoneNumberWithCountryCode(
                coach.phone_number,
                coach.phone_country_code ?? 1,
              )}
            </span>
          </a>
        )}
      </Table.Col>
      {(isAdmin || isCoachAdmin || isCoachSuperAdmin) && (
        <>
          <Table.Col>
            <span className="mr-2" onClick={toggleModal}>
              <i className="fe fe-user-check btn btn-md btn-secondary text-muted" />
            </span>
            <Dropdown
              triggerContent={
                <Button color="secondary" size="sm">
                  Actions
                </Button>
              }
              toggle={false}
              itemsObject={options}
              className="m-0 p-0"
            />
            <Text.Small className="d-block text-muted">
              {data?.getUserRolesByTeamId &&
                data?.getUserRolesByTeamId.map((role) => {
                  return (
                    <Tag key={`${coach.id}-${role.key}`} className="mt-1 mr-1">
                      {role?.name}
                    </Tag>
                  );
                })}
            </Text.Small>
          </Table.Col>
          <CoachSealEditModal
            teamId={teamId}
            coachId={coach?.id}
            isModalOpen={isSealModalOpen}
            toggleModal={setIsSealModalOpen}
          />
        </>
      )}
      <UserRoleModal
        action="addCoachRole"
        coachId={coach.id}
        isModalOpen={isModalOpen}
        name={`${coach.first_name} ${coach.last_name}`}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default StaffCoachListItem;
